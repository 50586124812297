import { useMediaQuery } from '@ui/paintscout'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import type { Theme } from '@material-ui/core'
import { Card, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ClientSearchTable from '../../components/ClientSearchTable'
import ErrorMessage from '../../components/ErrorMessage'
import NewClientButton from '../../components/NewClientButton'

import { NavHeader } from '../../components/NavDrawer'

import type { ValueFilter } from 'json-to-lucene'
import { jsonToLucene } from 'json-to-lucene'

import { useSearchClientsLazyQuery } from '@paintscout/api'
import ClientSearchBar, { getFiltersFromLocation } from '../../components/ClientSearchBar/ClientSearchBar'
import { setUrlQuery, getUrlQuery } from 'shared/util/routing'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  searchBar: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      gridColumn: '1 / 3'
    }
  },
  searchContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    },
    '& input': {
      minWidth: 400,
      [theme.breakpoints.down('md')]: {
        minWidth: 260
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset'
      }
    }
  },
  filterChips: {
    '&$filterChips': {
      gridRow: 3,
      gridColumn: '1 / -1',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(-1)
    }
  }
}))

export interface SearchProps {
  text: string
  filters: ValueFilter[]
  isInitialSearch?: boolean
}

export interface UrlParams {
  sort: string
  page: number
  search: string
}

export default function SearchClients() {
  const classes = useStyles({})
  const location = useLocation<{ page: number }>()
  const history = useHistory()
  const urlParams = getUrlQuery<UrlParams>({ location })
  const sort = urlParams.sort || '-updated_date'
  const { enqueueSnackbar } = useSnackbar()
  const smDown = useMediaQuery('sm')
  const limit = 50
  const [page, setPage] = useState(location.state?.page ?? 1)
  const [filters, setFilters] = useState(getFiltersFromLocation(location))
  const [query, setQuery] = useState('') // will get populated from ContactSearchBar

  const [search, { loading, data, error, fetchMore }] = useSearchClientsLazyQuery({
    variables: {
      query,
      limit
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (newData) => {
      // reset page back to 1 if user refreshes the page (cache is reset)
      if (
        page * limit > newData?.searchClients?.rows.length &&
        newData?.searchClients?.rows.length !== newData?.searchClients?.total_rows
      ) {
        setPage(1)
      }
    }
  })

  function handleClientSearch({ text, filters, isInitialSearch }: SearchProps) {
    let query = jsonToLucene({ query: text ?? '', filters }) || '*:*'
    if (query === '') {
      query = '*:*'
    }

    setUrlQuery({
      location,
      params: { search: text || null, filters },
      replaceParams: true
    })

    setQuery(query)
    setFilters(filters)

    if (!isInitialSearch) {
      setPage(1)
    }

    search({
      variables: {
        query,
        sort: [sort, '-created'],
        limit
      }
    })
  }

  useEffect(() => {
    history.replace({
      ...history.location,
      state: { page }
    })
  }, [page, history])

  // show error toast
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Unable to search Clients`, { variant: 'error' })
    }
  }, [error, enqueueSnackbar])

  const handleRequestSort = (ev: React.MouseEvent, name: string, direction: 'asc' | 'desc') => {
    const newSort = `${direction === 'desc' ? '-' : ''}${name}`

    setPage(1)
    setUrlQuery({ location, params: { sort: newSort } })

    search({
      variables: {
        query: query ?? '*:*',
        sort: [newSort, '-created']
      }
    })
  }

  const { total_rows = 0, rows = [] } = data?.searchClients ?? {}

  return (
    <div className={classes.root}>
      <NavHeader title="Clients" />
      <NewClientButton />
      <ClientSearchBar
        classes={{
          clientSearchRoot: classes.searchBar,
          container: classes.searchContainer,
          filterChips: classes.filterChips
        }}
        fullWidth={smDown ? true : false}
        loading={loading}
        filters={filters}
        showChips={true}
        onSearch={(query, filters, isInitialSearch) => {
          handleClientSearch({
            text: query,
            filters,
            isInitialSearch
          })
        }}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <Card>
        <ClientSearchTable
          clients={loading ? [] : rows}
          loading={loading}
          rowsPerPage={limit}
          sort={sort}
          page={page}
          pages={Math.ceil(total_rows / limit)}
          onPageChange={(newPage) => setPage(newPage)}
          onRequestSort={handleRequestSort}
          onFetchNextPage={() => {
            fetchMore({
              variables: {
                query: query ?? '*:*',
                limit,
                bookmark: data.searchClients.bookmark
              },
              updateQuery(prev, { fetchMoreResult }) {
                if (!fetchMoreResult) {
                  return prev
                }

                return {
                  ...prev,
                  searchClients: {
                    ...prev.searchClients,
                    bookmark: fetchMoreResult.searchClients.bookmark,
                    rows: [...prev.searchClients.rows, ...fetchMoreResult.searchClients.rows]
                  }
                }
              }
            })
          }}
        />
      </Card>
    </div>
  )
}
