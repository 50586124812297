import { getHighestRole } from '../../../users/getHighestRole'
import type { Auth0User, OptionsDocument, Permission } from 'paintscout'

export function getPermissions(user: Auth0User, options: OptionsDocument): Permission[] {
  // !! THIS FUNCTION MUST BE KEPT IN SYNC WITH UPDATE APP METADATA AND ADD TO TOKEN !!

  const roles = user.app_metadata.roles

  const role = getHighestRole(roles)

  let permissions = []

  // superadmin & admins ALWAYS have ALL permissions
  if (role === 'superadmin' || role === 'admin') {
    return [
      'create:quotes',
      'read:all-quotes',
      'write:all-quotes',
      'send:all-quotes',
      'accept:all-quotes',
      'additional-work:all-quotes',

      'create:contacts',
      'read:all-contacts',
      'write:all-contacts',

      'create:invoices',
      'read:all-invoices',
      'write:all-invoices',
      'send:all-invoices',

      'write-presentation-section:all-quotes',

      'write:account-settings',
      'assign:all-documents',
      'accept-payment:all-documents',

      'read:estimator-select'
    ]
  } else if (role === 'sales') {
    permissions = [
      'create:quotes',
      'read:assigned-quotes',
      'write:assigned-quotes',
      'send:assigned-quotes',
      'accept:assigned-quotes',
      'additional-work:assigned-quotes',

      'create:contacts',
      'read:all-contacts',
      'write:all-contacts',
      'read:assigned-contacts',
      'write:assigned-contacts',

      'create:invoices',
      'read:assigned-invoices',
      'write:assigned-invoices',
      'send:assigned-invoices',

      'write-presentation-section:assigned-quotes',

      'assign:assigned-documents',
      'accept-payment:assigned-documents'
    ]
  } else if (role === 'crew') {
    permissions = [
      'read:assigned-quotes',
      'additional-work:assigned-quotes',

      'read:all-contacts',
      'read:assigned-contacts',

      'read:assigned-invoices',
      'accept-payment:assigned-documents',

      'read:estimator-select',

      'assign:assigned-documents'
    ]
  } else if (role === 'viewer') {
    permissions = [
      'read:all-quotes',
      'read:all-contacts',
      'read:all-invoices',
      'read:estimator-select',
      'send:all-quotes',
      'send:all-invoices',
      'additional-work:all-quotes',
      'accept-payment:assigned-documents'
    ]
  } else {
    permissions = []
  }

  const include =
    options &&
    options.options &&
    options.options.permissions &&
    options.options.permissions[role] &&
    options.options.permissions[role].include
      ? options.options.permissions[role].include
      : []
  const exclude =
    options &&
    options.options &&
    options.options.permissions &&
    options.options.permissions[role] &&
    options.options.permissions[role].exclude
      ? options.options.permissions[role].exclude
      : []

  include.forEach(function (permission) {
    permissions.push(permission)
  })

  permissions = permissions.filter(function (permission) {
    return !exclude.includes(permission)
  })

  return permissions
}
