import cloneDeep from 'lodash/cloneDeep'
import type { EmailOptions, OrderableOption, TermsOptions } from 'paintscout'

export function removeQuoteTypesFromOption(option: OrderableOption, keys: string[]) {
  return keys.reduce((acc, key) => removeQuoteTypeFromOption({ ...option, ...acc }, key), option)
}

function removeQuoteTypeFromOption(option: OrderableOption, key: string): OrderableOption {
  const updatedOption = cloneDeep(option)

  if (updatedOption.quoteTypeOrder) {
    delete updatedOption.quoteTypeOrder[key]
  }

  Object.keys(updatedOption.values || {}).forEach((itemKey) => {
    const index = updatedOption.values[itemKey].quoteTypes?.indexOf(key)
    if (index !== -1) {
      updatedOption.values[itemKey].quoteTypes?.splice(index, 1)
    }
  })

  return updatedOption
}

export function removeQuoteTypesFromTerms(terms: TermsOptions, keys: string[]) {
  return keys.reduce((acc, key) => removeQuoteTypeFromTerms({ ...terms, ...acc }, key), terms)
}

function removeQuoteTypeFromTerms(terms: TermsOptions, key: string): TermsOptions {
  const updatedTerms = cloneDeep(terms)

  if (updatedTerms.quoteTypeOrder) {
    delete updatedTerms.quoteTypeOrder[key]
  }

  Object.keys(updatedTerms.values).forEach((itemKey) => {
    const index = updatedTerms.values[itemKey].quoteTypes?.indexOf(key)
    if (index !== -1) {
      updatedTerms.values[itemKey].quoteTypes?.splice(index, 1)
    }
  })

  return updatedTerms
}

export function removeQuoteTypesFromEmails(emails: EmailOptions, keys: string[]) {
  return keys.reduce((acc, key) => removeQuoteTypeFromEmails({ ...emails, ...acc }, key), emails)
}

function removeQuoteTypeFromEmails(emails: EmailOptions, key: string): EmailOptions {
  const updatedEmail = cloneDeep(emails)
  if (Object.keys(updatedEmail?.followUps?.values ?? {}).length) {
    Object.keys(updatedEmail.followUps.values).forEach((itemKey) => {
      const index = updatedEmail.followUps.values[itemKey].quoteTypes?.indexOf(key)
      if (index !== -1) {
        updatedEmail.followUps.values[itemKey].quoteTypes?.splice(index, 1, '__all__')
      }
    })
  }

  if (Object.keys(updatedEmail?.customTemplates?.values ?? {}).length) {
    Object.keys(updatedEmail.customTemplates.values).forEach((itemKey) => {
      const index = updatedEmail.customTemplates.values[itemKey].defaultFor?.indexOf(key)
      if (index !== -1) {
        updatedEmail.customTemplates.values[itemKey].defaultFor?.splice(index, 1)
      }
    })
  }

  return updatedEmail
}
