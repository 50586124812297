/**
 * @module builder
 */
import identity from 'lodash/identity'
import sortBy from 'lodash/sortBy'
import type { OptionsDocument, PresentationSectionOption } from 'paintscout'
import { getObjectLabels } from '../../../util/get-object-labels'
import { getFeature } from '../../get-features'

export function getPresentationSectionOptions(args: {
  options?: OptionsDocument
  type?: string
}): PresentationSectionOption[] {
  const { type, options } = args
  const objectLabels = getObjectLabels({ options })
  const hasAboutYourEstimator = getFeature({ options, path: 'presentation.aboutYourEstimator' })

  let sectionOptions = [
    hasAboutYourEstimator
      ? {
          type: 'about-estimator',
          label: 'About Your Estimator',
          title: 'About Your Estimator',
          active: true,
          advanced: {
            useForNav: false,
            allowCustom: true
          }
        }
      : null,
    {
      type: 'cover',
      label: 'Cover Page',
      title: 'Cover',
      background: { noPadding: false, color: '#fff' },
      fields: {
        subtitle: 'Estimate\nDescription',
        alignment: 'justify',
        verticalAlign: 'middle',
        backgroundOpacity: 1,
        overlayImage: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        }
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'custom',
      label: 'Custom HTML/CSS',
      title: 'New Custom Section',
      fields: {
        html: '<blockquote class="blockquote">Pellentesque habitant morbi tristique senectus<br />et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, <br />feugiat vitae, ultricies eget, tempor sit amet, ante.</blockquote><pre><code>Morbi in\nsem quis\ndui placerat ornare.\nPellentesque odio nisi, \neuismod in</code></pre>',
        css: '.blockquote {border-left: 5px solid #ddd; margin-left: 0; padding-left: 20px;margin-bottom:20px;} pre {background: #ddd; padding: 20px; color: #000; display: inline-block; border-radius: 3px;}'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'divider',
      label: 'Divider',
      title: 'Divider',
      background: { noPadding: false, color: '#fff' },
      fields: {
        marginTop: 40,
        marginBottom: 40,
        marginTopResponsive: 'md',
        marginBottomResponsive: 'md'
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'documents',
      label: 'Documents',
      title: 'Important Documents',
      background: { noPadding: false, color: '#fff' },
      fields: {
        rows: [
          {
            title: 'Document Title',
            content:
              '<p>Describe the details and/or the importance of the associated document in the box provided here.</p>',
            image: {
              src: '',
              format: 'pdf',
              cloudinaryPublicId: '',
              width: 612,
              height: 792,
              type: 'upload',
              visibility: 'visible'
            }
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'image',
      label: 'Image',
      title: '',
      background: { noPadding: false, color: '#fff' },
      fields: {
        image: null,
        placeholder: ''
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'sliderImage',
      label: 'Slider Image',
      title: '',
      background: { noPadding: false, color: '#fff' },
      fields: {
        leftImage: null,
        rightImage: null,
        placeholder: ''
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'gallery',
      label: 'Gallery',
      title: '',
      background: { noPadding: false, color: '#fff' },
      fields: {
        columns: 3,
        showBorder: false
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'awards',
      label: 'Images Row',
      title: 'Images Row',
      background: { noPadding: false, color: '#fff' },
      fields: {
        alignment: 'middle',
        rows: [
          {
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/award-placeholder.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/award-placeholder',
              width: 272,
              height: 326,
              type: 'upload',
              visibility: 'visible'
            }
          },
          {
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/award-placeholder.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/award-placeholder',
              width: 272,
              height: 326,
              type: 'upload',
              visibility: 'visible'
            }
          },
          {
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/award-placeholder.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/award-placeholder',
              width: 272,
              height: 326,
              type: 'upload',
              visibility: 'visible'
            }
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'introduction',
      label: 'Introduction',
      background: { noPadding: false, color: '#fff' },
      title: '',
      fields: {
        image: null as any,
        content: ''
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: true
      }
    },
    {
      type: 'mission',
      label: 'Centered Text',
      title: 'Centered Text',
      background: { noPadding: false, color: '#fff' },
      fields: {
        content:
          '<p>Nulla ornare nulla luctus magna elementum, et ullamcorper odio feugiat.</p><p><strong>Maecenas efficitur quis massa ac pretium.</strong></p>',
        image: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        }
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'pictures',
      label: `${objectLabels.quote.value} Pictures`,
      title: `${objectLabels.quote.value} Pictures`,

      fields: {},
      background: {
        style: 'contained',
        color: '#fff'
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'quote',
      label: objectLabels.quote.value,
      title: objectLabels.quote.value,
      fields: {
        // the below are for the button component that triggers the cart
        asCartLink: false,
        label: `View ${objectLabels.quote.value}`,
        color: '',
        buttonSize: 'sm',
        horizontalAlignInContainer: 'center'
      },
      background: {
        style: 'contained',
        color: '#fff'
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'services',
      label: 'Two Column Rows',
      title: 'Two Column Rows',
      background: { noPadding: false, color: '#fff' },
      fields: {
        rows: [
          {
            title: 'Title',
            content:
              '<p>Fusce eget ipsum felis. Aenean elit leo, interdum at aliquam ac, blandit quis tellus. Sed accumsan urna in pretium dignissim. Suspendisse imperdiet libero vel purus mattis auctor. Morbi imperdiet enim ac nulla pulvinar, vitae tempus eros fermentum. Duis id neque diam.</p>',
            image: {
              src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
              format: 'png',
              cloudinaryPublicId: 'placeholders/image',
              width: 100,
              height: 100,
              type: 'upload',
              visibility: 'visible'
            }
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'terms',
      label: 'Terms',
      title: 'Terms',
      useForNav: true,
      allowCustom: false,
      active: true,
      fields: {},
      background: {
        style: 'contained',
        color: '#fff'
      },
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'testimonials',
      label: 'Testimonials',
      title: 'Testimonials',
      background: { noPadding: false, color: '#fff' },
      fields: {
        rows: [
          {
            content: '<p>Nullam eget magna non sapien fermentum maximus id in erat.</p>',
            credit: 'Bob Johnson'
          },
          {
            content: '<p>Sed gravida magna sed sapien efficitur rhoncus.</p>',
            credit: 'Steve Williams'
          },
          {
            content: '<p>Nulla euismod tempor vehicula.</p>',
            credit: 'Gary Jones'
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: 'socials',
      label: 'Socials',
      title: 'Socials',
      background: { noPadding: false, color: '#fff' },
      fields: {
        rows: [
          {
            link: 'https://www.instagram.com',
            icon: 'instagram'
          },
          {
            link: 'https://www.facebook.com',
            icon: 'facebook'
          },
          {
            link: 'https://www.youtube.com',
            icon: 'youtube'
          }
        ]
      },
      active: true,
      advanced: {
        useForNav: false,
        allowCustom: false
      }
    },
    {
      type: '2-column',
      label: '2 Column',
      title: 'My New Section',
      background: { noPadding: false, color: '#fff', useTheme: true },
      fields: {
        animation: 'none',
        columnAlign: 'center',
        fullWidthImage: true,
        verticalAlign: 'middle',
        leftType: 'image',
        leftOptions: { color: '', backgroundColor: '' },
        leftImage: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        },
        leftWidth: 6,
        rightType: 'text',
        rightOptions: { color: '', backgroundColor: '' },
        rightText:
          '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis risus sem in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. Nulla euismod tempor vehicula.</p><p></p><p>Phasellus efficitur turpis tempus, viverra velit ut, fringilla magna.</p>',
        rightWidth: 6
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },

    {
      type: 'text',
      label: 'Text Block',
      title: 'New Text Section',
      background: { noPadding: false, color: '#fff' },
      fields: {
        content:
          '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis risus sem in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. Nulla euismod tempor vehicula.</p><p></p><p>Phasellus efficitur turpis tempus, viverra velit ut, fringilla magna.</p>'
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: 'work-order',
      label: objectLabels.workOrder.value,
      title: objectLabels.workOrder.value,

      fields: {},
      background: {
        style: 'contained',
        color: '#fff'
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    },
    {
      type: '3-column',
      label: '3 Column',
      title: 'My New Section',
      background: { noPadding: false, color: '#fff' },
      fields: {
        animation: 'none',
        columnAlign: 'center',
        verticalAlign: 'middle',
        leftType: 'image',
        leftOptions: { color: '', backgroundColor: '' },
        leftImage: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        },
        centerType: 'text',
        centerOptions: { color: '', backgroundColor: '' },
        centerText:
          '<p>Morbi ultrices, augue ut fermentum euismod, orci dui dignissim libero, ac mollis risus sem in nisi.</p><p></p><p>Sed cursus nibh vel placerat dapibus. Maecenas semper vestibulum congue. Nulla euismod tempor vehicula.</p>',
        rightType: 'image',
        rightOptions: { color: '', backgroundColor: '' },
        rightImage: {
          src: 'https://res.cloudinary.com/taptapquote/image/upload/v1581797484/placeholders/image.png',
          format: 'png',
          cloudinaryPublicId: 'placeholders/image',
          width: 100,
          height: 100,
          type: 'upload',
          visibility: 'visible'
        }
      },
      active: true,
      advanced: {
        useForNav: true,
        allowCustom: false
      }
    }
    // ? Might need this for later
    // {
    //   type: 'sherwin-williams-popular-colors',
    //   label: 'sherwin-williams-popular-colors',
    //   title: 'sherwin-williams-popular-colors'
    // }
  ].filter(Boolean)

  sectionOptions = sectionOptions.filter(identity)
  sectionOptions = sortBy(sectionOptions, ['label'])

  if (type) {
    return sectionOptions.filter((option) => option.type === type)
  }

  return sectionOptions
}
