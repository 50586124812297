import React, { useState } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import MenuIcon from '@material-ui/icons/Menu'

import { Menu } from '@material-ui/core'
import { MenuItem } from '@ui/paintscout'

import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopy'

import classnames from 'classnames'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer'
  },
  menu: {}
}))

export interface PageOptionsMenuProps {
  onRemove?: () => void
  onCopy?: () => void
  disabled?: boolean
}

export default function PageOptionsMenu({ disabled, onRemove, onCopy, ...props }: PageOptionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles(props)

  return (
    <div className={classnames({ [classes.root]: !disabled })}>
      <MenuIcon onClick={handleOpen} />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={!!anchorEl}>
        <MenuItem onClick={handleRemove} icon={DeleteIcon}>
          Remove
        </MenuItem>
        <MenuItem onClick={handleCopy} icon={CopyIcon}>
          Copy
        </MenuItem>
      </Menu>
    </div>
  )

  function handleRemove(_: React.MouseEvent) {
    setAnchorEl(null)
    if (onRemove) {
      onRemove()
    }
  }

  function handleCopy(_: React.MouseEvent) {
    setAnchorEl(null)
    if (onCopy) {
      onCopy()
    }
  }

  function handleOpen(ev: React.MouseEvent) {
    if (disabled) {
      return null
    }

    setAnchorEl(ev.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
}
