import React, { useMemo } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { FormSection, FormSectionTitle, InputField, Typography, useClientOptions } from '../../'
import EventSubscriptionType from './EventSubscriptionType'
import type { EventType, ProviderConfig } from 'paintscout'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'
import Checkbox from './../../Checkbox'

const useStyles = makeStyles<Theme, DropboxProviderFormProps>(
  (theme) => ({
    root: {},
    configSection: {
      marginLeft: theme.spacing(4),
      cursor: 'pointer'
    }
  }),
  { name: 'DropboxProviderForm' }
)

export interface DropboxProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
}

export function DropboxProviderForm(props: DropboxProviderFormProps) {
  const { providerConfig, providerName, isSettingsIntegration = false, isAdminIntegrations = false, onChange } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })

  const { handleEventChange, handleConfigChange, handleConfigToggleChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  return (
    <>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'Export Quotes'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-accepted' as EventType}
              event={providerConfig.events['quote-accepted']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are accepted`}
            />
            {providerConfig.events['quote-accepted']?.enabled && (
              <>
                <div onClick={handleConfigToggleChange('quoteAcceptedQuotePdf')}>
                  <Grid container alignItems="center" classes={{ root: classes.configSection }}>
                    <Grid item>
                      <Checkbox checked={!!providerConfig.config.quoteAcceptedQuotePdf} />
                    </Grid>
                    <Grid item>
                      <Typography>Export PDF of {objectLabels.quote.value}</Typography>
                    </Grid>
                  </Grid>
                </div>
                <div onClick={handleConfigToggleChange('quoteAcceptedWorkOrderPdf')}>
                  <Grid container alignItems="center" classes={{ root: classes.configSection }}>
                    <Grid item>
                      <Checkbox checked={!!providerConfig.config.quoteAcceptedWorkOrderPdf} />
                    </Grid>
                    <Grid item>
                      <Typography>Export PDF of {objectLabels.workOrder.value}</Typography>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <EventSubscriptionType
              isAdminIntegrations={isAdminIntegrations}
              disableRadio={isSettingsIntegration}
              name={'quote-sent' as EventType}
              event={providerConfig.events['quote-sent']}
              onChange={handleEventChange}
              label={`When ${objectLabels.quote.plural} are sent`}
            />
            {providerConfig.events['quote-sent']?.enabled && (
              <>
                <div onClick={handleConfigToggleChange('quoteSentQuotePdf')}>
                  <Grid container alignItems="center" classes={{ root: classes.configSection }}>
                    <Grid item>
                      <Checkbox checked={!!providerConfig.config.quoteSentQuotePdf} />
                    </Grid>
                    <Grid item>
                      <Typography>Export PDF of {objectLabels.quote.value}</Typography>
                    </Grid>
                  </Grid>
                </div>
                <div onClick={handleConfigToggleChange('quoteSentWorkOrderPdf')}>
                  <Grid container alignItems="center" classes={{ root: classes.configSection }}>
                    <Grid item>
                      <Checkbox checked={!!providerConfig.config.quoteSentWorkOrderPdf} />
                    </Grid>
                    <Grid item>
                      <Typography>Export PDF of {objectLabels.workOrder.value}</Typography>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </FormSection>
      <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
        <FormSectionTitle title={'General'} variant={'h4'} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputField
              name={'filenamePrefix'}
              type={'text'}
              value={providerConfig.config.filenamePrefix}
              label={'Filename Prefix'}
              sublabel={'Files saved to Dropbox will be prefix with this text. (ie: paintscout-quotes-)'}
              fullWidth={true}
              onChange={handleConfigChange}
              autoFocus={true}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}
