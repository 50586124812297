import React, { useState, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import FormControl from '@material-ui/core/FormControl'
import Done from '@material-ui/icons/Done'
import clone from 'lodash/clone'
import find from 'lodash/find'
import type { EmailTemplate, OptionsDocument } from 'paintscout'
import { getFeature, getObjectLabels, getQuoteTypeOptions } from '@paintscout/util/builder'
import Button from '../../Button'
import Checkbox from '../../Checkbox'
import type { DialogProps } from '../../Dialog'
import Dialog from '../../Dialog'
import { DialogActions, DialogContent, DialogTitle } from '../../Dialog'
import FormControlLabel from '../../FormControlLabel'
import Grid from '../../Grid'
import InputField from '../../InputField'
import { Editor } from '../../Editor'
import ImportJsonDialog from '../../ImportJsonDialog'
import ExportJsonDialog from '../../ExportJsonDialog'
import DialogStackContext from '../../DialogStack/DialogStackContext'
import { getTemplateContext, getTemplateTags } from '@paintscout/util/util'
import { useUser } from '../../UserProvider'
import MenuItem from '../../MenuItem'
import SplitMenuButton from '../../SplitMenuButton'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import { useClientOptions } from '../../ClientOptionsProvider'
import type { DropdownSelectOption } from '../../DropdownSelect'
import DropdownSelect from '../../DropdownSelect'
import uniqBy from 'lodash/uniqBy'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  expansion: {
    borderRadius: theme.typography.pxToRem(0),
    border: `0.15rem solid ${theme.palette.text.primary}`
  },
  expansionSummary: {
    backgroundColor: theme.palette.text.primary,
    '& span': {
      color: 'white'
    }
  },
  templateField: {
    marginTop: theme.typography.pxToRem(20)
  },
  chipInput: {
    minHeight: theme.typography.pxToRem(0),
    '& $input': {
      minHeight: theme.typography.pxToRem(0)
    }
  }
}))

export interface EmailTemplateDialogProps extends DialogProps {
  classes?: StyleClasses<typeof useStyles>
  item: EmailTemplate
  customTemplate?: boolean
  addressBook?: any
  options?: OptionsDocument
  isAdmin?: boolean
  toHidden?: boolean
  onConfirm?: (updatedTemplate: EmailTemplate) => void
  onCancel?: () => void
  onEmailMigrate?: (selectedTemplate: EmailTemplate) => void
}

function EmailTemplateDialog(props: EmailTemplateDialogProps) {
  const classes = useStyles(props)
  const {
    loading,
    item,
    onCancel,
    onConfirm,
    customTemplate,
    addressBook,
    options,
    isAdmin: isAdminSite,
    toHidden,
    onEmailMigrate,
    ...otherProps
  } = props

  const { preferences, isAdmin: isAdminUser } = useUser()
  const editorPreference = preferences?.editor ?? 'always'
  const emailSubjectRef = useRef(null)
  const emailBodyRef = useRef(null)
  const hasProfileCommunications = !getFeature({
    options,
    preferences,
    path: 'settingsCommunications.enabled'
  })

  const { isAdmin } = useClientOptions()
  const canEdit = isAdminUser || hasProfileCommunications || isAdmin

  const [template, setTemplate] = useState(clone(item))
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  /**
   * Set template.additionalRecipients form for Selections
   * with value and label.
   */
  const recipientsList = (template?.additionalRecipients || []).map((email) => {
    return { value: email, label: email }
  })
  const [recipients, setRecipients] = useState(recipientsList)

  const initialAddressBookList: DropdownSelectOption[] = (addressBook ?? []).map((email: string) => {
    return { value: email, label: email }
  })

  const [addressBookList, setAddressBookList] = useState(
    uniqBy([...initialAddressBookList, ...recipientsList], 'value')
  )

  const hasAttachPdf = getFeature({ options, path: 'quotes.emails.attachPdf' })
  const hasReminders = getFeature({ options, path: 'quotes.emails.reminders' }) && template.name === 'quote-reminder'

  const objectLabels = getObjectLabels({ options })

  const allowDisable = typeof template.enabled !== 'undefined'

  const templateContext = getTemplateContext(editorPreference)
  const templateTags = getTemplateTags({ options, invoice: template.name === 'send-invoice-client' })

  const reminderPeriodOptions = [
    { value: 2, label: '2 days' },
    { value: 3, label: '3 days' },
    { value: 5, label: '5 days' },
    { value: 7, label: '7 days' }
  ]

  const reminderRetryOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' }
  ]

  const reminderStatusOptions = [
    { label: 'Estimate has been Viewed', value: 'viewed' },
    { label: 'Estimate has been Accepted or Declined', value: 'accepted' }
  ]

  const quoteTypeOptions = getQuoteTypeOptions({ options, hideAll: true })
    .map((type) => ({
      value: type.key,
      label: type.label
    }))
    .sort((a) => (a.value === 'all' ? -1 : 1))

  // Helper functions
  const handleImport = () => {
    openDialog(ImportJsonDialog, {
      onImport: (json: { [key: string]: any }) => {
        // Clean name on json import so our new key from template works
        const { name: _name, ...cleanJson } = json
        setTemplate({
          ...template,
          ...(cleanJson as unknown as EmailTemplate)
        })
        dismissDialog()
      },
      onClose: dismissDialog
    })
  }

  const handleExport = () => {
    openDialog(ExportJsonDialog, {
      item: template,
      onConfirm: dismissDialog
    })
  }

  const handleDefaultQuoteTypeChange = (values: string[]) => {
    setTemplate({
      ...template,
      defaultFor: values
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setTemplate((template) => ({
      ...template,
      [name]: value
    }))
  }

  const handleAddressBookChange = (values: DropdownSelectOption[]) => {
    setRecipients(
      values.map((item: DropdownSelectOption) => {
        return { value: item.value, label: item.value }
      })
    )
    setTemplate({
      ...template,
      additionalRecipients: values.map((item: DropdownSelectOption) => item.value)
    })
  }

  const handleCheckboxChange = (name: string) => (event: any, value: boolean) => {
    setTemplate({
      ...template,
      [name]: value
    })
  }

  const handleReminderSelectChange = (name: string) => (value: DropdownSelectOption) => {
    setTemplate({
      ...template,
      [name]: value.value
    })
  }

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (onConfirm) {
      onConfirm({
        ...template,
        subject: emailSubjectRef.current.getHTML(),
        body: emailBodyRef.current.getHTML()
      })
    }
  }

  // Checkboxes
  const pdfCheckbox = <Checkbox checked={template.attachPdf} onChange={handleCheckboxChange('attachPdf')} />
  const enabledCheckbox = <Checkbox checked={template.enabled} onChange={handleCheckboxChange('enabled')} />
  const reminderCheckbox = (
    <Checkbox checked={template.reminderDefault} onChange={handleCheckboxChange('reminderDefault')} />
  )

  // Buttons
  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      Cancel
    </Button>
  )
  const rightButton = (
    <div style={{ display: 'flex' }}>
      <SplitMenuButton
        onClick={handleImport}
        variant={'contained'}
        color={'primary'}
        disabled={loading}
        Icon={ArrowIcon}
        label={'Import'}
      >
        <MenuItem onClick={handleExport}>Export</MenuItem>
        {onEmailMigrate && (
          <MenuItem
            onClick={() =>
              onEmailMigrate({
                ...template,
                subject: emailSubjectRef.current.getHTML(),
                body: emailBodyRef.current.getHTML()
              })
            }
          >
            Move to Settings
          </MenuItem>
        )}
      </SplitMenuButton>
    </div>
  )

  return (
    <Dialog classes={{ root: classes.root }} maxWidth={'md'} fullWidth={true} {...otherProps}>
      <DialogTitle loading={loading} rightContent={isAdminSite && rightButton}>
        Edit Template - {customTemplate ? template.label : template.label}
      </DialogTitle>
      <DialogContent>
        <form id="edit-template-dialog-form" onSubmit={handleSave}>
          {customTemplate && (
            <div>
              <InputField
                label="Title"
                value={template.label}
                fullWidth={true}
                name={'label'}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {!toHidden && (
            <div className={customTemplate ? classes.templateField : ''}>
              <DropdownSelect
                value={recipients}
                options={addressBookList}
                label={'TO'}
                sublabel={'Select email from address book or add new email'}
                searchable
                fullWidth
                variant="multi"
                onChange={handleAddressBookChange}
                noOptionsMessage={'Enter email address'}
                onCreateOption={(newOption: string) => {
                  const newAddressBookList = [...addressBookList, { value: newOption, label: newOption }]
                  setAddressBookList(newAddressBookList)
                  handleAddressBookChange([...recipients, { value: newOption, label: newOption }])
                }}
                popperModifiers={{
                  flip: {
                    enabled: false
                  },
                  preventOverflow: {
                    enabled: false
                  }
                }}
              />
            </div>
          )}

          <div className={customTemplate || !toHidden ? classes.templateField : ''}>
            <Editor
              label={'Subject'}
              singleLine={true}
              key={template.subject}
              content={template.subject}
              templateContext={templateContext}
              disabled={!canEdit}
              toolbar={{
                bold: false,
                italic: false,
                underline: false,
                color: false,
                highlight: false,

                lists: false,
                link: false,
                template: {
                  tags: templateTags
                }
              }}
              fullWidth={true}
              ref={emailSubjectRef}
            />
          </div>
          <div className={classes.templateField}>
            <Editor
              label={'Body'}
              key={template.body}
              content={template.body}
              templateContext={templateContext}
              toolbar={{
                color: false,
                highlight: false,
                template: {
                  tags: templateTags
                }
              }}
              fullWidth={true}
              disabled={!canEdit}
              ref={emailBodyRef}
            />
          </div>
          {hasAttachPdf && (
            <div>
              <FormControl>
                <FormControlLabel
                  disabled={!canEdit}
                  control={pdfCheckbox}
                  label="Attach PDF"
                  style={{ marginRight: 0 }}
                />
              </FormControl>
            </div>
          )}
          {hasReminders && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControlLabel
                  disabled={!canEdit}
                  control={reminderCheckbox}
                  label="Send Reminder by Default"
                  style={{ marginRight: 0 }}
                />
              </Grid>
              <Grid item>
                <DropdownSelect
                  variant="single"
                  label={'Reminder Period'}
                  disabled={!canEdit}
                  fullWidth={true}
                  value={find(reminderPeriodOptions, { value: template.reminderPeriod })}
                  onChange={handleReminderSelectChange('reminderPeriod')}
                  options={reminderPeriodOptions}
                />
              </Grid>
              <Grid item>
                <DropdownSelect
                  variant="single"
                  label={'Number of Reminders'}
                  fullWidth={true}
                  disabled={!canEdit}
                  value={find(reminderRetryOptions, { value: template.reminderRetries })}
                  onChange={handleReminderSelectChange('reminderRetries')}
                  options={reminderRetryOptions}
                />
              </Grid>
              <Grid item>
                <DropdownSelect
                  variant="single"
                  label={'Send Reminders Until'}
                  fullWidth={true}
                  disabled={!canEdit}
                  value={find(reminderStatusOptions, { value: template.reminderStatus })}
                  onChange={handleReminderSelectChange('reminderStatus')}
                  options={reminderStatusOptions}
                />
              </Grid>
            </Grid>
          )}
          {customTemplate && (
            <div className={classes.templateField}>
              <DropdownSelect
                variant="multi"
                fullWidth
                label={`Use as Default for ${objectLabels.quote.value} Type(s)`}
                disabled={!canEdit}
                onChange={(options) => {
                  handleDefaultQuoteTypeChange(options.map((item) => item.value))
                }}
                options={quoteTypeOptions}
                value={quoteTypeOptions.filter((option) => template?.defaultFor?.includes(option.value))}
              />
            </div>
          )}
          {allowDisable && (
            <div>
              <FormControl>
                <FormControlLabel
                  disabled={!canEdit}
                  control={enabledCheckbox}
                  label="Enabled"
                  style={{ marginRight: 0 }}
                />
              </FormControl>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions showBorder={true} leftButton={leftButton}>
        <Button disabled={loading} type="submit" form="edit-template-dialog-form" variant={'contained'} icon={Done}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EmailTemplateDialog
