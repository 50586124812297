import Grid from '@material-ui/core/Grid'
import type { Theme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import Email from '@material-ui/icons/Email'
import { Field, FastField, useFormikContext, Form } from 'formik'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { getObjectLabels, getFeature } from '@paintscout/util/builder'
import UploadImage from '../../UploadImage'
import Button from '../../Button'
import { useClientOptions } from '../../ClientOptionsProvider'

import { FormikInputField, FormikHtmlEditor } from '../../formik'
import { NewFormSection as FormSection } from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import PhoneNumberInput, { PhoneNumberExtensionInput } from '../../PhoneNumberInput'
import SignatureCanvas from '../../SignatureCanvas'
import type { Auth0UserWithClaims } from '../../UserProvider/UserContext'
import type { UserPreferencesDocument } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import SettingsPage from '../../SettingsPage'

const useStyles = makeStyles<Theme, UserProfileProps>(
  (theme) => ({
    root: {},
    contactInfo: {
      paddingBottom: theme.typography.pxToRem(15)
    },
    changeInfoButtons: {
      maxWidth: 450
    },
    changeInfoButton: {
      margin: theme.typography.pxToRem(15),
      marginLeft: 0
    },
    canvasButton: {
      justifyContent: 'left'
    },
    presentation: {}
  }),
  { name: 'UserProfileForm' }
)

export interface UserProfileProps {
  classes?: StyleClasses<typeof useStyles>
  disableSignature?: boolean
  allowEmailChange?: boolean
  showChangePassword?: boolean

  onChangePassword?: () => any
}

function UserProfileForm(props: UserProfileProps) {
  const classes = useStyles(props)
  const { showChangePassword, disableSignature, allowEmailChange } = props
  const { options } = useClientOptions()
  const { values, setFieldValue, errors } = useFormikContext<{
    user: Auth0UserWithClaims
    preferences: UserPreferencesDocument
  }>()
  const { enqueueSnackbar } = useSnackbar()
  const roles = values?.user?.app_metadata?.roles ?? []
  const canBeSales = roles.includes('sales') || roles.includes('superadmin') || roles.includes('admin')
  const objectLabels = getObjectLabels({ options })
  const hasPresentations = getFeature({ options, path: 'presentation.enabled' })
  const hasAboutYourEstimator = getFeature({ options, path: 'presentation.aboutYourEstimator' })

  const handlePresentationUpload = (image: {
    src: string
    cloudinaryPublicId?: string
    s3PublicKey?: string
    format: string
    width: number
    height: number
    type: string
  }) => {
    setFieldValue('preferences.presentation.image' as any, image)
  }

  const handlePresentationClear = () => {
    setFieldValue('preferences.presentation.image' as any, null)
  }

  const handleSignatureChange = (event: any, data?: string) => {
    setFieldValue('preferences.signature' as any, data)
  }

  const handleSignatureUploadError = (type?: string) => {
    if (type) {
      enqueueSnackbar(`Unable to upload signature, ${type} file format is not currently supported.`, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar('Unable to upload signature', { variant: 'error' })
    }
  }

  return (
    <SettingsPage title="Profile">
      <Form className={classes.root}>
        {/* <PageHelpMessage
        newHelpMessage
        path="profile-account"
        openContent={
          <Typography>
            This is where you can update your personal information or change your password!
            <LearnMoreLink link="http://help.paintscout.com/en/articles/3206825-updating-profile-estimator-information" />
          </Typography>
        }
        closedContent={null}
      /> */}
        <FormSection>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={6} lg={6} xl={6}>
              <Field
                component={FormikInputField}
                name="user.email"
                disabled={!allowEmailChange}
                margin="dense"
                label={'Email'}
                icon={Email}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid container={true} className={classes.changeInfoButtons}>
            {showChangePassword && (
              <Grid item={true} xs={12} sm={6}>
                <Button className={classes.changeInfoButton} icon={EditIcon} onClick={props.onChangePassword}>
                  Change Password
                </Button>
              </Grid>
            )}
          </Grid>
        </FormSection>
        <FormSection>
          <FormSectionTitle
            title={'Contact Information'}
            variant={'h4'}
            subTitle={`Shown on ${objectLabels.quote.plural.toLowerCase()} to help customers contact you.`}
          />
          <Grid container spacing={2} className={classes.contactInfo}>
            <Grid item xs={6} sm={5}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.firstName"
                label={'First Name'}
                required
                fullWidth
                helperTextState="error"
                helperText={get(errors, 'user.user_metadata.firstName')}
              />
            </Grid>
            <Grid item xs={6} sm={5}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.lastName"
                label={'Last Name'}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.contactInfo}>
            <Grid item xs={6} sm={3}>
              <Field
                component={FormikInputField}
                inputComponent={PhoneNumberInput}
                inputProps={{ separator: options?.options?.phoneNumbers?.separator }}
                name="user.user_metadata.phoneNumber"
                label={'Phone Number'}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <Field
                component={FormikInputField}
                inputComponent={PhoneNumberExtensionInput}
                name="user.user_metadata.phoneExtension"
                label={'EXT.'}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Field component={FormikInputField} name="user.user_metadata.title" label={'Title'} fullWidth />
            </Grid>
          </Grid>
        </FormSection>
        {canBeSales && (
          <FormSection>
            <FormSectionTitle
              title={'Signature'}
              variant={'h4'}
              subTitle={`Place your signature here to auto-populate on accepted ${objectLabels.quote.plural.toLowerCase()}.`}
            />
            {!disableSignature ? (
              <SignatureCanvas
                newSignatureButtons
                disabled={disableSignature}
                caption={'Sign Here'}
                classes={{ captionRow: classes.canvasButton }}
                value={values.preferences?.signature ?? ''}
                onChange={handleSignatureChange}
                onUploadError={handleSignatureUploadError}
                allowUpload={true}
              />
            ) : (
              <img src={values.preferences.signature} style={{ width: '100%', maxWidth: 450, height: 150 }} />
            )}
          </FormSection>
        )}
        {hasPresentations && canBeSales && hasAboutYourEstimator && (
          <FormSection>
            <FormSectionTitle
              title={'Presentation'}
              variant={'h4'}
              subTitle={
                'More information about you, which can be added as an "About Your Estimator" section of presentations'
              }
            />
            <Grid container={true} spacing={4} className={classes.presentation}>
              <Grid item={true} xs={12} sm={6} lg={6} xl={6}>
                <UploadImage
                  cloudinaryPublicId={values?.preferences?.presentation?.image?.cloudinaryPublicId}
                  s3PublicKey={values?.preferences?.presentation?.image?.s3PublicKey}
                  src={values?.preferences?.presentation?.image?.src}
                  onUpload={handlePresentationUpload}
                  onClear={handlePresentationClear}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} lg={6} xl={6}>
                <FastField
                  component={FormikHtmlEditor}
                  name="preferences.presentation.description"
                  margin="dense"
                  label={'Description/Bio'}
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormSection>
        )}
      </Form>
    </SettingsPage>
  )
}

export default UserProfileForm
