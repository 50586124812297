import React, { useState, useRef } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { DialogProps, DropdownSelectOption, EditorMethods } from '@ui/paintscout'
import FormControl from '@material-ui/core/FormControl'
import Done from '@material-ui/icons/Done'
import {
  Grid,
  Button,
  Checkbox,
  CoatsSelector,
  Dialog,
  DialogActions,
  DialogContent,
  EditableDialogTitle,
  FormControlLabel,
  FormSection,
  FormSectionTitle,
  Editor,
  InputField,
  RateTypeSelect,
  Tooltip,
  Typography,
  useDialogs,
  DropdownSelect
} from '@ui/paintscout'
import type { EditableRate, Product, ProductOption } from '@paintscout/util/builder'
import { getFeature, getObjectLabels, getProductOptions, getProductOption } from '@paintscout/util/builder'
import classnames from 'classnames'
import type { OptionsDocument } from 'paintscout'
import { makeHtml } from '@paintscout/util/util'
import find from 'lodash/find'
import ProductSelectDialog from '../ProductSelectDialog'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

const useStyles = makeStyles<Theme, EditRateOptionDialogProps>(
  (theme) => ({
    root: {},
    input: {},
    rateSelect: {
      display: 'grid',
      gridTemplateColumns: '0.7fr 0.3fr',
      gridGap: theme.spacing(2)
    },
    defaultDimensionsSection: {
      marginTop: theme.spacing(2)
    },
    labelSection: {},
    rateSection: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridGap: theme.spacing()
    },
    checkboxLabel: {
      ...theme.typography.body1,
      whiteSpace: 'nowrap'
    },
    useCoatsSection: {
      display: 'grid',
      gridTemplateColumns: 'min-content auto'
    },
    coatsSection: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 0.25fr) 0.05fr 0.35fr',
      columnGap: theme.spacing(2),
      rowGap: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },
    coatsSectionSpacer: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    noCoatsSection: {},
    badgeRoot: {
      marginBottom: '5px'
    },
    editorLabel: {
      display: 'block',
      color: theme.palette.grey.A400,
      fontWeight: 500,
      fontSize: '12px',
      letterSpacing: '0.15em',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      '& span': {
        color: theme.palette.error.main,
        position: 'absolute',
        top: 2.5
      }
    },
    checkboxAndButton: {
      marginTop: theme.spacing(2.5)
    }
  }),
  { name: 'EditRateOptionDialog' }
)

export interface EditRateOptionDialogProps extends DialogProps {
  item: EditableRate
  options: OptionsDocument
  quoteType: string
  isNew?: boolean
  onConfirm: (updatedRate: EditableRate, isDirty?: boolean) => void
  onCancel?: () => void
}

function EditRateOptionDialog(props: EditRateOptionDialogProps) {
  const { item: originalItem, options, quoteType, onConfirm, onCancel, isNew, ...baseDialogProps } = props
  const classes = useStyles(props)
  const objectLabels = getObjectLabels({ options })
  const applicationsLabel = objectLabels.application.plural
  const { openDialog, dismissDialog } = useDialogs()
  const features = getFeature({ options, path: 'quotes.areaSubstrates' })
  const advancedOptionsFeature = getFeature({ options, path: 'quotes.areaSubstrates.advancedOptions' })
  const multiProductRatesFeature = getFeature({ options, path: 'quotes.areaSubstrates.multiProductRates' })

  const values = [...originalItem.values]

  // This is a little bit of magic in the event that this rate was
  // created when we were making !useCoats = 2 coats.
  // These should continue to calculate correctly, but we'll need
  // to make sure it still shows in the correct spot on the form.
  if (!originalItem.useCoats && values[1] && !values[0]) {
    values[0] = values[1]
  }

  const [item, setItem] = useState<EditableRate>({
    ...originalItem,
    defaultApplications: originalItem.useCoats ? originalItem.defaultApplications : 1,
    values
  })
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const areaReportTextRef = useRef<EditorMethods>(null)
  const reportTextRef = useRef<EditorMethods>(null)
  const notesRef = useRef<EditorMethods>(null)

  const [defaultProducts, setDefaultProducts] = useState(getDefaultRateProducts({ options, quoteType, rate: item }))
  // -- Just for legacy single item support
  const productOptions = getProductOptions({ options, quoteType })
  const productDropdownOptions: DropdownSelectOption[] = [
    {
      value: 'none',
      label: 'None'
    },
    ...productOptions.map((product) => ({
      value: product.key,
      label: product.label
    }))
  ]
  const [legacyDefaultProduct, setLegacyDefaultProduct] = useState(defaultProducts[0] ?? null)
  let legacyCoverageLabel = null
  const defaultUnitLabel = defaultProducts[0]?.unitLabel ? defaultProducts[0].unitLabel : null
  if (item.rateType === 'lnft') {
    legacyCoverageLabel = (
      <>
        Coverage ({objectLabels.unit.abbreviation.linear}/{defaultUnitLabel ? defaultUnitLabel : 'gal'})
      </>
    )
  } else if (item.rateType === 'quantity') {
    legacyCoverageLabel = <>Coverage (gal/{defaultUnitLabel ? defaultUnitLabel : 'item'})</>
  } else {
    legacyCoverageLabel = (
      <>
        Coverage ({objectLabels.unit.abbreviation.square}/{defaultUnitLabel ? defaultUnitLabel : 'gal'})
      </>
    )
  }
  // -- End of legacy stuff

  const showDefaultDimensionsSection =
    ['sqftWalls', 'sqftFloor', 'sqftCeiling', 'cuft', 'lnft'].includes(item.rateType) &&
    (advancedOptionsFeature?.defaultDimensions ?? false)
  const defaultDimensionsUnit = objectLabels.unit.value === 'Metre' ? 'm' : item.useInches ? 'IN' : 'FT'

  const initialCrewNote = makeHtml(item.notes)

  const handleChangeDefaultApplications = (value) => {
    setItem((prevState) => ({
      ...prevState,
      defaultApplications: value
    }))
    setIsDirty(true)
  }

  const handleCoverageChange = ({ coverage, index }: { coverage: string; index: number }) => {
    setDefaultProducts((defaultProducts) => {
      return defaultProducts.map((product, i) => {
        if (i === index) {
          return { ...product, coverage }
        }
        return product
      })
    })
    setIsDirty(true)
  }

  const handleDefaultProductChange = (option: DropdownSelectOption) => {
    if (option.value === 'none') {
      setLegacyDefaultProduct(null)
      setDefaultProducts([])
      setIsDirty(true)
      return
    }
    const productOption = getProductOption({ options, key: option.value })
    const newDefault = {
      product: productOption,
      unitLabel: productOption.unitLabel,
      coverage: legacyDefaultProduct?.coverage ?? null
    }
    setLegacyDefaultProduct(newDefault)
    setDefaultProducts([newDefault])
    setIsDirty(true)
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    // Catch names that are values[x], and update the values array for that index
    if (name.includes('values[')) {
      const index = parseInt(name.replace('values[', '').replace(']', ''), 10)
      const newValues = [...item.values]
      newValues[index] = value
      setItem((updatedItem) => ({
        ...updatedItem,
        values: newValues
      }))
      setIsDirty(true)
    } else {
      setItem((updatedItem) => ({
        ...updatedItem,
        [name]: value
      }))
      setIsDirty(true)
    }
  }

  const handleRichTextChange = () => {
    if (!isDirty) {
      setIsDirty(true)
    }
  }

  const handleRateTypeChange = (rateType, calculationType) => {
    setItem((prevState) => ({
      ...prevState,
      rateType,
      calculationType
    }))
    setIsDirty(true)
  }

  const handleCheckboxChange = (name) => (ev, value) => {
    setItem((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setIsDirty(true)
  }

  const handleUseCoatsChange = (ev, value) => {
    const newRate = {
      ...item,
      useCoats: value,
      defaultApplications: value ? item.defaultApplications : 1
    }

    setItem(newRate)
    setIsDirty(true)
  }

  const handleAddProduct = () => {
    openDialog(ProductSelectDialog, {
      allowCustom: false,
      onConfirm: (product: Product, _custom?: boolean) => {
        const productOption = getProductOption({ options, key: product.key })
        setDefaultProducts((defaultProducts) => {
          return [...defaultProducts, { product: productOption, unitLabel: productOption?.unitLabel, coverage: null }]
        })
        setIsDirty(true)
        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      },
      quoteTypes: ['all'],
      rateType: ['all'],
      options
    })
  }

  const handleConfirm = () => {
    const isFlatRate = item.calculationType === 'price'
    // Get ref vals
    const richTextVals = {
      notes: notesRef.current?.getHTML(),
      reportText: reportTextRef?.current.getHTML(),
      areaReportText: areaReportTextRef?.current.getHTML()
    }
    const cleanItem = {
      ...item,
      ...richTextVals,
      values: item.values.map((value) => Number(value)),
      defaultProducts: defaultProducts.map((defaultProductValue) => {
        return {
          productKey: defaultProductValue.product.key,
          unitLabel: defaultProductValue.unitLabel,
          coverage: defaultProductValue.coverage
        }
      })
    }
    delete cleanItem.defaultProduct
    delete cleanItem.defaultUnitLabel
    delete cleanItem.coverage

    const updatedRate = isFlatRate
      ? {
          ...cleanItem,
          prep: undefined,
          useCoats: false,
          showCoats: false
        }
      : cleanItem

    const dirty = isFlatRate || isDirty
    onConfirm(updatedRate, dirty)
  }

  const handleRemoveProduct = (index: number) => {
    setDefaultProducts((defaultProducts) => {
      return defaultProducts.filter((product, i) => i !== index)
    })
    setIsDirty(true)
  }

  const handleToggle = () => {
    setItem((prevState) => ({
      ...prevState,
      active: !prevState.active
    }))
    setIsDirty(true)
  }

  const leftButton = (
    <Button className={classes.cancel} variant={'text'} onClick={onCancel}>
      {'< Back'}
    </Button>
  )

  return (
    <Dialog className={classes.root} maxWidth={'md'} fullWidth={true} {...baseDialogProps}>
      <EditableDialogTitle
        title={`${isNew ? 'Add' : 'Edit'} Rate`}
        onToggle={handleToggle}
        toggleValue={typeof item.active !== 'undefined' ? !!item.active : true}
        toggleLabel="Active"
      />
      <DialogContent>
        <form
          id="rate-option-form"
          onSubmit={(e) => {
            e.preventDefault()
            handleConfirm()
          }}
          onKeyDown={(keyEvent) => {
            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
              keyEvent.preventDefault()
            }
          }}
        >
          <div className={classnames(`rate-type-${item.rateType}`, `calculation-type-${item.calculationType}`)}>
            <FormSection
              hideDivider={true}
              style={{ paddingTop: 0 }}
              classes={{
                root: classnames(classes.labelSection)
              }}
            >
              <Grid container={true} spacing={3} justifyContent="center">
                <Grid item={true} xs={12} sm={12} md={6}>
                  <InputField
                    classes={{ root: classes.input }}
                    label={'Internal Label'}
                    name={'label'}
                    value={item.label}
                    toolTip={`Your customer cannot see this label. Will only appear to you when building the ${objectLabels.quote.value.toLowerCase()} and on the ${objectLabels.workOrder.value.toLowerCase()} for your team to see.`}
                    fullWidth={true}
                    onChange={handleChange}
                    required={true}
                    error={!item.label}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6}>
                  <InputField
                    classes={{ root: classes.input }}
                    label={'Client Label'}
                    name={'clientLabel'}
                    value={item.clientLabel}
                    fullWidth={true}
                    toolTip={`This is the label your customer will see on the ${objectLabels.quote.value.toLowerCase()}.`}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection classes={{ root: classes.rateSection }}>
              <FormSectionTitle title={'Rate Calculation'} variant={'h4'} />
              <RateTypeSelect
                rateType={item.rateType}
                calculationType={item.calculationType}
                onChange={handleRateTypeChange}
              />
              <FormControl classes={{ root: classnames(classes.useCoatsSection, 'coats-field') }}>
                {item.calculationType !== 'price' && (
                  <FormControlLabel
                    control={<Checkbox checked={!!item.useCoats} onChange={handleUseCoatsChange} />}
                    label={`Use ${applicationsLabel.toLowerCase()} to calculate rate.`}
                    style={{ marginRight: 0 }}
                    classes={{ root: classes.checkboxLabel }}
                  />
                )}
              </FormControl>
              {item.useCoats && item.calculationType !== 'price' && (
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3} md={2}>
                    <InputField
                      fullWidth
                      label={`1 ${applicationsLabel}`}
                      name={'values[0]'}
                      format={'rate'}
                      onChange={handleChange}
                      value={item.values[0]}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <InputField
                      fullWidth
                      label={`2 ${applicationsLabel}`}
                      name={'values[1]'}
                      format={'rate'}
                      onChange={handleChange}
                      value={item.values[1]}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <InputField
                      fullWidth
                      label={`3 ${applicationsLabel}`}
                      name={'values[2]'}
                      format={'rate'}
                      onChange={handleChange}
                      value={item.values[2]}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <InputField
                      fullWidth
                      label={`4 ${applicationsLabel}`}
                      name={'values[3]'}
                      format={'rate'}
                      onChange={handleChange}
                      value={item.values[3]}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={4}>
                    <CoatsSelector
                      label={`Default # of ${applicationsLabel}`}
                      onChange={handleChangeDefaultApplications}
                      value={item.defaultApplications}
                      possibleValues={item.values}
                    />
                  </Grid>
                </Grid>
              )}
              {!item.useCoats && (
                <div className={classnames(classes.noCoatsSection, 'rate-field')}>
                  <InputField
                    label={`Rate Value`}
                    format={'rate'}
                    name={'values[0]'}
                    onChange={handleChange}
                    value={item.values[0]}
                  />
                </div>
              )}
              {item.calculationType !== 'price' && advancedOptionsFeature?.prepHours && (
                <div>
                  <InputField
                    label={`Prep (HR)`}
                    format={'hours'}
                    name={`prep`}
                    onChange={handleChange}
                    value={item.prep}
                  />
                </div>
              )}
              {showDefaultDimensionsSection && (
                <div className={classes.defaultDimensionsSection}>
                  <Tooltip
                    title={'Default Dimensions'}
                    content={`These dimensions will be used to override the area dimensions when this rate is used on a ${objectLabels.quote.value.toLowerCase()}.`}
                  >
                    <FormSectionTitle title={'Default Dimensions'} variant={'h4'} />
                  </Tooltip>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <InputField
                        label={`Length (${defaultDimensionsUnit})`}
                        format={'dimension'}
                        name={`defaultLength`}
                        fullWidth
                        onChange={handleChange}
                        value={item.defaultLength}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        label={`Width (${defaultDimensionsUnit})`}
                        format={'dimension'}
                        name={`defaultWidth`}
                        fullWidth
                        onChange={handleChange}
                        value={item.defaultWidth}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputField
                        label={`Height (${defaultDimensionsUnit})`}
                        format={'dimension'}
                        name={`defaultHeight`}
                        fullWidth
                        onChange={handleChange}
                        value={item.defaultHeight}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </FormSection>
            {multiProductRatesFeature ? (
              <FormSection>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 500 }} variant={'h6'}>
                      {`Default ${multiProductRatesFeature ? 'Products' : 'Product'}`}
                    </Typography>
                  </Grid>
                  {defaultProducts.length > 0 && (
                    <Grid item xs={12}>
                      {defaultProducts.map((productValues, index) => {
                        let coverageLabel = null
                        const defaultUnitLabel = productValues?.unitLabel ? productValues.unitLabel : null
                        if (item.rateType === 'lnft') {
                          coverageLabel = (
                            <>
                              Coverage ({objectLabels.unit.abbreviation.linear}/
                              {defaultUnitLabel ? defaultUnitLabel : 'gal'})
                            </>
                          )
                        } else if (item.rateType === 'quantity') {
                          coverageLabel = <>Coverage (gal/{defaultUnitLabel ? defaultUnitLabel : 'item'})</>
                        } else {
                          coverageLabel = (
                            <>
                              Coverage ({objectLabels.unit.abbreviation.square}/
                              {defaultUnitLabel ? defaultUnitLabel : 'gal'})
                            </>
                          )
                        }
                        return (
                          <>
                            <Grid
                              className={classes.root}
                              container
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                <InputField
                                  fullWidth
                                  value={String(productValues.product.label)}
                                  onChange={() => {}}
                                  disabled={true}
                                  label={<>Name</>}
                                />
                              </Grid>
                              <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                                <InputField
                                  fullWidth
                                  value={String(productValues.coverage)}
                                  onChange={(ev) => {
                                    const { value } = ev.target
                                    handleCoverageChange({ coverage: value, index })
                                  }}
                                  format={'rate'}
                                  label={coverageLabel}
                                  toolTip={`If this Rate requires a different product coverage rate, enter it here. Otherwise, the Coverage for the Product will be used.`}
                                />
                              </Grid>
                              <Grid item xs={2} className={classes.checkboxAndButton}>
                                <IconButton
                                  size="small"
                                  className={classes.removeButton}
                                  onClick={() => handleRemoveProduct(index)}
                                >
                                  <RemoveCircleIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </>
                        )
                      })}
                    </Grid>
                  )}
                  {((!multiProductRatesFeature && defaultProducts.length === 0) || multiProductRatesFeature) && (
                    <Grid item xs={12}>
                      <Button variant={'outlined'} color={'primary'} onClick={() => handleAddProduct()}>
                        {' '}
                        + Add Product
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </FormSection>
            ) : (
              <FormSection>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={6}>
                    <DropdownSelect
                      variant="single"
                      label="Default Product"
                      placeholder={{ selectPlaceholder: 'Select Product...', plural: 'Products' }}
                      searchable
                      options={productDropdownOptions}
                      value={
                        legacyDefaultProduct
                          ? productDropdownOptions.find((option) => option.value === legacyDefaultProduct?.product?.key)
                          : null
                      }
                      onChange={handleDefaultProductChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      fullWidth
                      value={legacyDefaultProduct?.coverage}
                      onChange={(item) => {
                        const { value } = item.target
                        handleCoverageChange({ coverage: value, index: 0 })
                        setLegacyDefaultProduct((prev) => {
                          return {
                            ...prev,
                            coverage: value
                          }
                        })
                      }}
                      format={'rate'}
                      label={legacyCoverageLabel}
                      toolTip={`If this Rate requires a different product coverage rate, enter it here. Otherwise, the Coverage for the Product will be used.`}
                    />
                  </Grid>
                </Grid>
              </FormSection>
            )}
            {(features.areaViewDescription || features.substrateViewDescription) && (
              <FormSection>
                <Grid container={true} spacing={3} justifyContent="flex-start" alignItems="flex-end">
                  {features.areaViewDescription && (
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                      <Tooltip
                        classes={{ badgeRoot: classes.badgeRoot }}
                        title={'Area View Description'}
                        content={`Added to the item description on the Area View. Visible to Customers`}
                      >
                        <Typography className={classes.editorLabel}>{'Area View Description'}</Typography>
                      </Tooltip>
                      <Editor
                        content={item.areaReportText}
                        onChange={handleRichTextChange}
                        ref={areaReportTextRef}
                        fullWidth={true}
                      />
                    </Grid>
                  )}
                  {features.substrateViewDescription && (
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                      <Tooltip
                        classes={{ badgeRoot: classes.badgeRoot }}
                        title={`${objectLabels.substrate.value} View Description`}
                        content={`Added to the item description on the ${objectLabels.substrate.value} View. Visible to Customers`}
                      >
                        <Typography
                          className={classes.editorLabel}
                        >{`${objectLabels.substrate.value} View Description`}</Typography>
                      </Tooltip>
                      <Editor
                        content={item.reportText}
                        ref={reportTextRef}
                        onChange={handleRichTextChange}
                        fullWidth={true}
                      />
                    </Grid>
                  )}
                </Grid>
              </FormSection>
            )}
            {features.crewNote && (
              <FormSection>
                <Tooltip
                  classes={{ badgeRoot: classes.badgeRoot }}
                  title={'Crew Note'}
                  content={`Shown for this item on the ${objectLabels.workOrder.value}. Visible to Crew Only.`}
                >
                  <Typography className={classes.editorLabel}>{'Crew Note'}</Typography>
                </Tooltip>
                <Editor content={initialCrewNote} ref={notesRef} onChange={handleRichTextChange} fullWidth={true} />
              </FormSection>
            )}
            {advancedOptionsFeature.enabled && (
              <FormSection>
                <FormSectionTitle title={'Advanced Options'} variant={'h4'} />
                <Grid container={true} alignItems={'flex-start'}>
                  {advancedOptionsFeature.workOrderOnly && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Hide From Customer'}
                        content={`Don't show this ${objectLabels.substrate.value} on the ${objectLabels.quote.value} items.`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox checked={item.workOrderOnly} onChange={handleCheckboxChange('workOrderOnly')} />
                          }
                          label={`Hide From Customer`}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.hideQuantity && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Hide Quantity From Customer'}
                        content={`Hide the quantity of this ${objectLabels.substrate.value} on ${objectLabels.quote.value} items.`}
                      >
                        <FormControlLabel
                          label={'Hide Quantity From Customer'}
                          control={
                            <Checkbox checked={item.hideQuantity} onChange={handleCheckboxChange('hideQuantity')} />
                          }
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.hideQuantityOnWorkOrder && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Hide Quantity From Crew'}
                        content={`Hide the quantity of this ${objectLabels.substrate.value} on ${objectLabels.workOrder.value} items.`}
                      >
                        <FormControlLabel
                          label={'Hide Quantity From Crew'}
                          control={
                            <Checkbox
                              checked={item.hideQuantityOnWorkOrder}
                              onChange={handleCheckboxChange('hideQuantityOnWorkOrder')}
                            />
                          }
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.showProduct && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Show Product'}
                        content={`Show the product associated with this ${objectLabels.substrate.value} on the ${objectLabels.quote.value}`}
                      >
                        <FormControlLabel
                          label={'Show Product'}
                          control={
                            <Checkbox checked={item.showProduct} onChange={handleCheckboxChange('showProduct')} />
                          }
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.showCoats && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Show Coats'}
                        content={`Show the coats associated with this ${objectLabels.substrate.value} on the ${objectLabels.quote.value}`}
                      >
                        <FormControlLabel
                          label={'Show Coats'}
                          control={<Checkbox checked={item.showCoats} onChange={handleCheckboxChange('showCoats')} />}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.showPrep && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Show Prep Hours'}
                        content={`Show the prep hours associated with this ${objectLabels.substrate.value} on the ${objectLabels.quote.value}`}
                      >
                        <FormControlLabel
                          label={'Show Prep Hours'}
                          control={<Checkbox checked={item.showPrep} onChange={handleCheckboxChange('showPrep')} />}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.showPrice && (
                    <Grid item={true} xs={12} sm={12}>
                      <Tooltip
                        title={'Show Price'}
                        content={`Show the price associated with this ${objectLabels.substrate.value} on the ${objectLabels.quote.value}`}
                      >
                        <FormControlLabel
                          label={'Show Price'}
                          control={<Checkbox onChange={handleCheckboxChange('showPrice')} checked={item.showPrice} />}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                  {advancedOptionsFeature.hourlyRate && (
                    <>
                      <Grid item xs={12}>
                        <Tooltip
                          title={'Custom Hourly Rate'}
                          content={`Override the ${objectLabels.quote.value} Hourly Rate for this ${objectLabels.substrate.value} only.`}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.overrideHourlyRate}
                                onChange={handleCheckboxChange('overrideHourlyRate')}
                              />
                            }
                            label={`Use Custom Hourly Rate`}
                          />
                        </Tooltip>
                      </Grid>
                      {item.overrideHourlyRate && (
                        <Grid item xs={12} sm={6}>
                          <InputField
                            value={item.hourlyRate}
                            onChange={handleChange}
                            name={'hourlyRate'}
                            fullWidth={true}
                            format={'price'}
                            label={'Custom Hourly Rate'}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {advancedOptionsFeature.useInches && (
                    <Grid item={true} xs={12}>
                      <Grid container={true} spacing={8} alignItems={'flex-end'}>
                        <Grid item={true} sm={5}>
                          <Tooltip
                            title={'Use Inches for Dimensions'}
                            content={`Use inches to override ${objectLabels.substrate.value} dimensions.`}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox checked={item.useInches} onChange={handleCheckboxChange('useInches')} />
                              }
                              label={`Use Inches for Dimensions`}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </FormSection>
            )}
          </div>
        </form>
      </DialogContent>
      <DialogActions showBorder={true} leftButton={leftButton}>
        <Button type="submit" form="rate-option-form" variant={'contained'} icon={Done}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const getDefaultRateProducts = ({
  options,
  quoteType,
  rate
}: {
  options: OptionsDocument
  quoteType: string
  rate: EditableRate
}): {
  product: ProductOption
  unitLabel: string
  coverage: string
}[] => {
  const defaultProducts: {
    product: ProductOption
    unitLabel: string
    coverage: string
  }[] = []
  const productOptions = getProductOptions({ options, quoteType })

  // Old style defaultProduct on an EditableRate
  let defaultProduct = find(productOptions, { key: rate.defaultProduct })
  if (rate.defaultProduct && !defaultProduct) {
    defaultProduct = getProductOption({ options, key: rate.defaultProduct })
  }
  if (defaultProduct) {
    defaultProducts.push({
      product: defaultProduct,
      unitLabel: rate?.defaultUnitLabel ?? defaultProduct.unitLabel,
      coverage: rate.coverage
    })
  }

  // New multi-products on an EditableRate
  if (rate?.defaultProducts?.length) {
    rate.defaultProducts.forEach((defaultProductValue) => {
      let defaultProduct = find(productOptions, { key: defaultProductValue.productKey })
      if (defaultProductValue && !defaultProduct) {
        defaultProduct = getProductOption({ options, key: defaultProductValue.productKey })
      }
      if (defaultProduct) {
        defaultProducts.push({
          product: defaultProduct,
          unitLabel: defaultProductValue?.unitLabel,
          coverage: defaultProductValue?.coverage
        })
      }
    })
  }

  return defaultProducts
}

export default EditRateOptionDialog
