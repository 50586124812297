import { useMutation } from '@apollo/react-hooks'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputField } from '@ui/paintscout'
import { gql } from 'apollo-boost'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

export interface NewReferralCodeDialogProps extends DialogProps {
  title: string
  prefix?: string
  // returns search result of client
  onConfirm: (any) => any
  onClose: () => void
}

export default function NewReferralCodeDialog({
  onConfirm,
  onClose,
  prefix,
  title,
  ...props
}: NewReferralCodeDialogProps) {
  const [loadingMutation, setLoadingMutation] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [createCoupon] = useMutation(gql`
    mutation createCoupon($input: JSON!) {
      createCoupon(input: $input) {
        name
        status
        id
        email
      }
    }
  `)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid spacing={2} container>
          <Grid item xs={4}>
            <InputField
              label="Code"
              value={code}
              required
              helperText="The coupon code to be used."
              fullWidth
              onChange={(e) => {
                setCode(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              label="Email Address"
              value={email}
              required
              helperText="The email address associated with this code."
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth
              label="Name"
              required
              value={name}
              helperText={`Name will be automatically prefixed with "${prefix}"`}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        leftButton={
          <Button variant="text" onClick={() => onClose()}>
            Cancel
          </Button>
        }
      >
        <Button onClick={handleConfirm} loading={loadingMutation}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleConfirm() {
    setLoadingMutation(true)
    try {
      const res = await createCoupon({
        variables: {
          input: {
            id: code,
            name: `${prefix}${name}`,
            discount_type: 'FIXED_AMOUNT',
            discount_amount: 10000,
            duration_type: 'ONE_TIME',
            apply_on: 'INVOICE_AMOUNT',
            currency_code: 'USD',
            meta_data: {
              email
            }
          }
        }
      })
      onConfirm(res.data.createCoupon)
    } catch (err) {
      setLoadingMutation(false)
      console.log(err)
      enqueueSnackbar('Unable to add code')
    }
  }
}
