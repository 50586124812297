import { Grid, styled } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { getObjectLabels } from '@paintscout/util/builder'
import {
  Collapse,
  FormSection,
  FormSectionTitle,
  FormikCheckbox,
  FormikFormSectionTitle,
  Tooltip,
  Typography
} from '@ui/paintscout'
import { Field, useFormikContext } from 'formik'
import type { OptionsDocument } from 'paintscout'
import React from 'react'

const ToggleableSection = styled('div')({
  paddingLeft: 20,
  paddingRight: 20
})

const ClientFeaturesForm = () => {
  const { values } = useFormikContext<{ options: OptionsDocument }>()
  const { options } = values
  const objectLabels = getObjectLabels({ options })

  return (
    <>
      <FormSectionTitle title="Features" />
      <FormSection>
        <Grid container spacing={3}>
          {/* Quotes  */}
          <Grid item xs={12}>
            <Field title="Quotes" name="options.options.features.quotes.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features?.quotes?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  {/* Quote views */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Views</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Area"
                          name="options.options.features.quotes.views.area"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label={`${objectLabels.substrate.plural}`}
                          name="options.options.features.quotes.views.substrate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Work Order"
                          name="options.options.features.quotes.views.workOrder"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Quote emails */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Emails</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="To Staff"
                          name="options.options.features.quotes.emails.toStaff"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Reminders"
                          name="options.options.features.quotes.emails.reminders"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Attach PDF"
                          name="options.options.features.quotes.emails.attachPdf"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Thank You"
                          name="options.options.features.quotes.emails.acceptThankYou"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Enable 'To:' field on sent email templates"
                          name="options.options.features.quotes.emails.allowToField"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Enable AddressBook emails"
                          name="options.options.features.quotes.emails.hasAddressBook"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Settings/Company Based Emails, Chat & Follow Ups"
                          name="options.options.features.settingsCommunications.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Quote sidebar */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Sidebar</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Activity"
                          name="options.options.features.quotes.sidebar.activity.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Details"
                          name="options.options.features.quotes.sidebar.details.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Calculation"
                          name="options.options.features.quotes.sidebar.calculation.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Presentation"
                          name="options.options.features.quotes.sidebar.presentation.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Terms"
                          name="options.options.features.quotes.sidebar.terms.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Advanced"
                          name="options.options.features.quotes.sidebar.advanced.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          label="Source/Deal"
                          name="options.options.features.quotes.sidebar.advanced.source"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Quote sidebar advanced */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Sidebar - Advanced</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Show Detailed Breakdown"
                          name="options.options.features.quotes.sidebar.advanced.displayOptions.showDetailedBreakdown"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label={`Stack Area ${objectLabels.substrate.plural}`}
                          name="options.options.features.quotes.sidebar.advanced.displayOptions.stackSubstrates"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide Work Order Material Cost"
                          name="options.options.features.quotes.sidebar.advanced.displayOptions.hideWorkOrderMaterialCost"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Contacts</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Address Suggestions"
                          name="options.options.features.quotes.addressValidation.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Contact Sources"
                          name="options.options.features.contactLeadSources.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Misc.</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Additional Work"
                          name="options.options.features.quotes.additionalWork"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Material Cost"
                          name="options.options.features.quotes.materialCost.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Split Tax Rate"
                          name="options.options.features.quotes.splitTaxRate.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Groups"
                          name="options.options.features.quotes.groups.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Acceptance Date Change"
                          name="options.options.features.quotes.changeAcceptanceDate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Scheduled Date"
                          name="options.options.features.quotes.scheduledDate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Secondary Contact"
                          name="options.options.features.quotes.secondaryContact.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Request Google Review (Beta)"
                          name="options.options.features.requestGoogleReview.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Payment Requests"
                          name="options.options.features.paymentRequests.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Product Order Form"
                          name="options.options.features.quotes.productOrderForm"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Tags"
                          name="options.options.features.quotes.tags.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Multiple Invoices"
                          name="options.options.features.quotes.multipleInvoices"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Convert to Invoice"
                          name="options.options.features.quotes.convertToInvoice"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Company Override Quote Types"
                          name="options.options.features.quotes.quoteTypeCompanyOverride"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Bulk Materials Editing"
                          name="options.options.features.quotes.bulkMaterialsEditing"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Editable Area Totals"
                          name="options.options.features.quotes.editableAreaTotals"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Custom ID Sequence"
                          name="options.options.features.quotes.customIDSequence"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide All Quote Type"
                          name="options.options.features.quotes.hideAllQuoteType"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Switch Button"
                          name="options.options.features.quotes.areaSubstrates.showSwitchButton"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Negative Values in Number Inputs"
                          name="options.options.features.allowNegativeNumberInputs"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Quote Sources"
                          name="options.options.features.quotes.quoteLeadSources.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Custom Quote Table Columns"
                          name="options.options.features.customQuoteTableColumns"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Line Items</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Line Item Media in Options"
                          name="options.options.features.quotes.lineItemMediaOptions.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Convert to Line Item"
                          name="options.options.features.quotes.convertToLineItem"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Field
                        label="Allow Hours Per Unit Line Items"
                        name="options.options.features.quotes.hoursPerUnitLineItems"
                        component={FormikCheckbox}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Advanced Rate Options</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Enabled"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Work Order Only"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.workOrderOnly"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide Quantities (Estimate)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.hideQuantity"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hide Quantities (Work Order)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.hideQuantityOnWorkOrder"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Coats"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showCoats"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Product"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showProduct"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Price"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showPrice"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Show Prep Hours"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.showPrep"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Exclude Tax"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.excludeTax"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Exclude Discount"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.excludeDiscount"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Hourly Rate"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.hourlyRate"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Default Rate Dimensions"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.defaultDimensions"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Prep Hours"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.prepHours"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Use Inches (Beta)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.useInches"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Cubic Feet (Beta)"
                          name="options.options.features.quotes.areaSubstrates.advancedOptions.cubicFeet"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Multiple Product Rates"
                          name="options.options.features.quotes.areaSubstrates.multiProductRates"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Client Settings</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Deleted Estimates Section"
                          name="options.options.features.quotes.deletedQuotes.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Markup Materials(Option Tile Multi-select)"
              name="options.options.features.markupMaterials.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Invoices"
              name="options.options.features.invoices.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features.invoices.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      <Grid item>
                        <Field
                          label="Invoice Terms"
                          name="options.options.features.invoiceTerms.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field title="Media" name="options.options.features.media.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features.media.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      <Grid item>
                        <Field
                          label="S3 Image Uploads"
                          name="options.options.features.s3ImageUpload.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Import Quote Image (Beta)"
              name="options.options.features.importQuoteImage.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.importQuoteImage?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      <Grid item>
                        <Field
                          label="OCR Imported Images"
                          name="options.options.features.importQuoteImage.ocr.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Import Image Only Option"
                          name="options.options.features.importQuoteImage.importOnly.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Presentations"
              name="options.options.features.presentation.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.presentation?.enabled}>
              <ToggleableSection>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Field
                      label="About Your Estimator Section"
                      name="options.options.features.presentation.aboutYourEstimator"
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Use Job Identifier in Cover Section subtitle"
                      name="options.options.features.presentation.jobIDInCoverSection"
                      component={FormikCheckbox}
                    />
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Payment Options (Beta)"
              name="options.options.features.paymentOptions.enabled"
              component={FormikFormSectionTitle}
            />
            <ToggleableSection>
              <Grid item xs={12}>
                <Tooltip
                  icon={<WarningIcon fontSize="small" style={{ color: '#F5A623' }} />}
                  interactive
                  title="Be careful turning this on for some people!"
                  content={
                    <>
                      Surcharging is illegal in Connecticut, Massachusetts, and Puerto Rico. See{' '}
                      <a
                        href="https://www.lawpay.com/about/blog/credit-card-surcharge-rules/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        this article
                      </a>{' '}
                      for more info.
                    </>
                  }
                >
                  <Field label="Surcharging" name="options.options.features.surcharge" component={FormikCheckbox} />
                </Tooltip>
              </Grid>
            </ToggleableSection>
          </Grid>
          <Grid item xs={12}>
            <Field title="Reports" name="options.options.features.reports.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features?.reports?.enabled}>
              <ToggleableSection>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Payments</Typography>
                    <Field
                      label="Payments Collected"
                      name="options.options.features.reports.paymentsCollected"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Outstanding Invoices"
                      name="options.options.features.reports.outstandingInvoices"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Financing Applications"
                      name="options.options.features.reports.financingApplications"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Payment Requests"
                      name="options.options.features.reports.paymentRequests"
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Estimates</Typography>
                    <Field
                      label="Accepted Estimates"
                      name="options.options.features.reports.acceptedEstimates"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Declined Estimates"
                      name="options.options.features.reports.declinedEstimates"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Options Accepted"
                      name="options.options.features.reports.optionsAccepted"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Additional Work"
                      name="options.options.features.reports.additionalWork"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Tagged Estimates"
                      name="options.options.features.reports.taggedEstimates"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Tag Summary"
                      name="options.options.features.reports.tagSummary"
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Estimators</Typography>
                    <Field
                      label="Estimator Performance"
                      name="options.options.features.reports.estimatorPerformance"
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Activity</Typography>
                    <Field
                      label="All Activity"
                      name="options.options.features.reports.allActivity"
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Advanced</Typography>
                    <Field
                      label="Integration Sources"
                      name="options.options.features.reports.integrationSources"
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Graphs</Typography>
                    <Field
                      label="Financing Graphs"
                      name="options.options.features.reports.financingGraphs"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Estimates Graphs"
                      name="options.options.features.reports.estimatesGraphs"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Estimators Graphs"
                      name="options.options.features.reports.estimatorsGraphs"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Activity Graphs"
                      name="options.options.features.reports.activityGraphs"
                      component={FormikCheckbox}
                    />
                    <Field
                      label="Advanced Graphs"
                      name="options.options.features.reports.advancedGraphs"
                      component={FormikCheckbox}
                    />
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <FormSectionTitle title="Editor" />
            <ToggleableSection>
              <Grid item xs={12}>
                <Field
                  label="Show Color Buttons"
                  name="options.options.features.editorOptions.textColor"
                  component={FormikCheckbox}
                />
              </Grid>
            </ToggleableSection>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Conversations"
              name="options.options.features.quotes.conversations.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Follow-Ups"
              name="options.options.features.quotes.followUps.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.quotes?.followUps?.enabled}>
              <ToggleableSection>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      label="SMS Follow-Ups"
                      name="options.options.features.quotes.followUps.sendText"
                      component={FormikCheckbox}
                    />
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Notifications"
              name="options.options.features.notifications.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.notifications?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Statistical Digest"
                          name="options.options.features.notifications.statsDigest"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label="Quote Viewed"
                          name="options.options.features.notifications.quoteViewed"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Integrations"
              name="options.options.features.integrations.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.integrations?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                  <Grid item>
                    <Field
                      label="Settings/Company Based Integrations"
                      name="options.options.features.settingsIntegrations.enabled"
                      component={FormikCheckbox}
                    />
                  </Grid>
                </Grid>
              </ToggleableSection>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Typography variant="h5">Options</Typography>

                  {/* Quote views */}
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">CompanyCam</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.companycam"
                          component={FormikCheckbox}
                        />
                      </Grid> */}

                      <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Stripe</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.stripe"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Freshbooks</Typography>
                              <Typography variant="body2">(Connection Only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.freshbooks"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Hubspot</Typography>
                              <Typography variant="body2">(Connection Only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.hubspot"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      <Grid item>
                        <Field
                          label="Search all people in Pipeline CRM, Deal-People only if unchecked"
                          name="options.options.pipelineKey.peopleSearch"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Sage</Typography>
                              <Typography variant="body2">(Connection only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.sage"
                          component={FormikCheckbox}
                        />
                      </Grid> */}

                      <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Xero</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.xero"
                          component={FormikCheckbox}
                        />
                      </Grid>

                      <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">ZenDesk Sell</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.getbase"
                          component={FormikCheckbox}
                        />
                      </Grid>

                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Wave</Typography>
                              <Typography variant="body2">(Connection Only, not ready for use)</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.wave"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                      <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Wisetack</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.wisetack"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Wisetack Staged Payouts</Typography>
                            </>
                          }
                          name="options.options.features.wisetackStagedPayouts"
                          component={FormikCheckbox}
                        />
                      </Grid>
                      {/* <Grid item>
                        <Field
                          label={
                            <>
                              <Typography variant="body1">Paintscout Api Keys</Typography>
                            </>
                          }
                          name="options.options.features.integrations.providers.paintscoutApiKeys"
                          component={FormikCheckbox}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Dashboard"
              name="options.options.features.dashboard.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.dashboard?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  {/* Quote views */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Sections</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Statistics"
                          name="options.options.features.dashboard.statistics"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <FormSectionTitle title="Quote List" />
            <ToggleableSection>
              <Grid container spacing={3}>
                <Grid item>
                  <Field
                    label="Show On Hold Tab"
                    name="options.options.features.quoteList.showOnHoldTab"
                    component={FormikCheckbox}
                  />
                </Grid>
              </Grid>
            </ToggleableSection>
          </Grid>
          <Grid item xs={12}>
            <Field title="SMS" name="options.options.features.sms.enabled" component={FormikFormSectionTitle} />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Templates"
              name="options.options.features.quotes.templates.enabled"
              component={FormikFormSectionTitle}
            />
            <Grid container spacing={3}>
              <Grid item>
                <Field
                  label="Smart Templates"
                  name="options.options.features.quotes.smartTemplate"
                  component={FormikCheckbox}
                />
              </Grid>
              <Grid item>
                <Field
                  label="Multi Quote-Type Smart Templates"
                  name="options.options.features.quotes.multiQuoteTemplates"
                  component={FormikCheckbox}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="LogRocket"
              name="options.options.features.logRocket.enabled"
              component={FormikFormSectionTitle}
            />
            <Grid container spacing={3}>
              <Grid item>
                <Field
                  label="Customer View LogRocket"
                  name="options.options.features.logRocket.customerView"
                  component={FormikCheckbox}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Field title="Billing" name="options.options.features.billing.enabled" component={FormikFormSectionTitle} />
          </Grid>
          <Grid item xs={12}>
            <Field title="Team" name="options.options.features.team.enabled" component={FormikFormSectionTitle} />
            <Collapse show={options.options.features?.team?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Sections</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field label="Crew" name="options.options.features.team.crew" component={FormikCheckbox} />
                      </Grid>
                      <Grid item>
                        <Field label="Viewer" name="options.options.features.team.viewer" component={FormikCheckbox} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Permissions</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Custom Permissions"
                          name="options.options.features.team.permissions.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Invoices</Typography>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          label="Crew Invoice Auto-Assignment"
                          name="options.options.features.team.crewInvoiceAutoAssign.enabled"
                          component={FormikCheckbox}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Font Scaling"
              name="options.options.features.fontScaling.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="ElasticSearch"
              name="options.options.features.database.elasticsearch.enabled"
              component={FormikFormSectionTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              title="Partner Content"
              name="options.options.features.partnerContent.enabled"
              component={FormikFormSectionTitle}
            />
            <Collapse show={options.options.features?.partnerContent?.enabled}>
              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      <Typography variant="h5">Sherwin-Williams</Typography>
                      <Grid container spacing={0} direction="column">
                        <Grid item>
                          <Field
                            label={'Presentation Pages'}
                            name="options.options.features.partnerContent.sherwinWilliams.presentationPages.enabled"
                            component={FormikCheckbox}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            icon={<WarningIcon fontSize="small" style={{ color: '#F5A623' }} />}
                            interactive
                            title="Under consuction"
                            content={'Awaiting deliverables, will be available in a future release'}
                          >
                            <Field
                              label={'Products'}
                              name="options.options.features.partnerContent.sherwinWilliams.products.enabled"
                              component={FormikCheckbox}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            icon={<WarningIcon fontSize="small" style={{ color: '#F5A623' }} />}
                            interactive
                            title="Under consuction"
                            content={'Awaiting deliverables, will be available in a future release'}
                          >
                            <Field
                              label={'Product Ordering'}
                              name="options.options.features.partnerContent.sherwinWilliams.productOrdering.enabled"
                              component={FormikCheckbox}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>

              <ToggleableSection>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="column">
                      <Typography variant="h5">Benjamin Moore</Typography>
                      <Grid container spacing={0} direction="column">
                        <Grid item>
                          <Field
                            label={'Presentation Pages'}
                            name="options.options.features.partnerContent.benjaminMoore.presentationPages.enabled"
                            component={FormikCheckbox}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            icon={<WarningIcon fontSize="small" style={{ color: '#F5A623' }} />}
                            interactive
                            title="Under consuction"
                            content={'Awaiting deliverables, will be available in a future release'}
                          >
                            <Field
                              label={'Products'}
                              name="options.options.features.partnerContent.benjaminMoore.products.enabled"
                              component={FormikCheckbox}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            icon={<WarningIcon fontSize="small" style={{ color: '#F5A623' }} />}
                            interactive
                            title="Under consuction"
                            content={'Awaiting deliverables, will be available in a future release'}
                          >
                            <Field
                              label={'Product Ordering'}
                              name="options.options.features.partnerContent.benjaminMoore.productOrdering.enabled"
                              component={FormikCheckbox}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ToggleableSection>
            </Collapse>
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientFeaturesForm
