import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import SettingsPage from '../../SettingsPage'
import { useFormikContext, Form } from 'formik'
import {
  getFeature,
  getFeatures,
  getOptionsProviderConfig,
  getProfileProviderConfig,
  updatePldConfig,
  getPermissions
} from '@paintscout/util/builder'
import type { OptionsDocument, ProviderConfig, UserPreferencesDocument } from 'paintscout'
import type { Auth0UserWithClaims } from '../../UserProvider/UserContext'
import UserProvider from '../../UserProvider/UserProvider'
import Typography from '../../Typography'
import {
  WorkGlueProviderForm,
  DropboxProviderForm,
  FreshbooksProviderForm,
  HubspotProviderForm,
  PipelineDealsProviderForm,
  QuickBooksProviderForm,
  BaseAdminProvider,
  SageProviderForm,
  XeroProviderForm,
  ZenDeskProviderForm
} from '../Integrations'
import debounce from 'lodash/debounce'

const useStyles = makeStyles<Theme, UserAdminIntegrationsFormProps>(
  (_theme) => ({
    root: {},
    providerTitle: {
      marginBottom: 0
    }
  }),
  { name: 'UserAdminIntegrationsForm' }
)

export interface UserAdminIntegrationsFormProps {
  classes?: StyleClasses<typeof useStyles>
}

function UserAdminIntegrationsForm(props: UserAdminIntegrationsFormProps) {
  const classes = useStyles(props)
  const { values, setValues, handleSubmit } = useFormikContext<{
    user: Auth0UserWithClaims
    preferences: UserPreferencesDocument
    options: OptionsDocument
  }>()

  const { preferences, user, options } = values
  const { integrations } = getFeatures({ options })
  const { wisetackSignup } = options.options
  const hasSettingsIntegrations = getFeature({ options, path: 'settingsIntegrations.enabled' })
  const providers = integrations && integrations.providers ? integrations.providers : {}
  const permissions = getPermissions(user, options)
  const [_loadingProvider, setLoadingProvider] = useState<string>('')
  const companyId = user?.app_metadata?.companyId
  const userId = user?.user_id

  const companyCamProviderConfig = getProfileProviderConfig({ preferences, name: 'companycam' })
  const dropboxProviderConfig = getProfileProviderConfig({ preferences, name: 'dropbox' })
  const freshbooksProviderConfig = getProfileProviderConfig({ preferences, name: 'freshbooks' })
  const pldProviderConfig = getOptionsProviderConfig({ options, name: 'pld' })
  const quickbooksProviderConfig = getProfileProviderConfig({ preferences, name: 'intuit' })
  const hubspotProviderConfig = getProfileProviderConfig({ preferences, name: 'hubspot' })
  const sageProviderConfig = getProfileProviderConfig({ preferences, name: 'sage' })
  const stripeProviderConfig = getProfileProviderConfig({ preferences, name: 'stripe' })
  const wisetackProviderConfig = getProfileProviderConfig({ preferences, name: 'wisetack' })
  const workglueProviderConfig = getProfileProviderConfig({ preferences, name: 'workglue' })
  const xeroProviderConfig = getProfileProviderConfig({ preferences, name: 'xero' })
  const zenDeskProviderConfig = getProfileProviderConfig({ preferences, name: 'getbase' })

  const updateProvider = debounce(function (providerName) {
    setLoadingProvider(providerName)
    handleSubmit()
    setLoadingProvider('')
  }, 2000)

  return (
    <SettingsPage title="Integrations">
      {hasSettingsIntegrations && (
        <Typography variant="h6" color="error">
          Client currently using Settings Integrations
        </Typography>
      )}
      <UserProvider
        user={{
          ...user,
          app_metadata: {
            ...user?.app_metadata,
            permissions
          }
        }}
        preferences={preferences}
      >
        <Form className={classes.root}>
          <BaseAdminProvider title={'CompanyCam'} providerConfig={companyCamProviderConfig} />
          <BaseAdminProvider title={'DropBox'} providerConfig={dropboxProviderConfig}>
            <DropboxProviderForm
              providerName="dropbox"
              isAdminIntegrations
              providerConfig={dropboxProviderConfig}
              onChange={handleChange}
              isSettingsIntegration={false}
            />
          </BaseAdminProvider>
          {providers.freshbooks && (
            <BaseAdminProvider title={'FreshBooks'} providerConfig={dropboxProviderConfig}>
              <FreshbooksProviderForm
                providerName="freshbooks"
                isAdminIntegrations
                providerConfig={freshbooksProviderConfig}
                onChange={handleChange}
                isSettingsIntegration={false}
              />
            </BaseAdminProvider>
          )}
          {providers.hubspot && (
            <BaseAdminProvider title={'Hubspot'} providerConfig={hubspotProviderConfig}>
              <HubspotProviderForm
                providerName="hubspot"
                isAdminIntegrations
                providerConfig={hubspotProviderConfig}
                onChange={handleChange}
                isSettingsIntegration={false}
              />
            </BaseAdminProvider>
          )}
          <BaseAdminProvider title="Pipeline CRM" providerConfig={pldProviderConfig}>
            <PipelineDealsProviderForm
              providerName="pld"
              isAdminIntegrations
              userId={userId}
              companyId={companyId}
              providerConfig={pldProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
          {providers.sage && (
            <BaseAdminProvider title="Sage" providerConfig={sageProviderConfig}>
              <SageProviderForm
                providerName="sage"
                isAdminIntegrations
                userId={userId}
                companyId={companyId}
                providerConfig={sageProviderConfig}
                onChange={handleChange}
              />
            </BaseAdminProvider>
          )}
          {providers.stripe && (
            <BaseAdminProvider title={'Stripe'} providerConfig={stripeProviderConfig}>
              <Typography variant="body1">Settings found in client admin-settings payments section</Typography>
            </BaseAdminProvider>
          )}
          <BaseAdminProvider title={'QuickBooks'} providerConfig={quickbooksProviderConfig}>
            <QuickBooksProviderForm
              providerName="intuit"
              isAdminIntegrations
              userId={userId}
              companyId={companyId}
              providerConfig={quickbooksProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
          {providers.wisetack && (
            <BaseAdminProvider title={'Wisetack'} providerConfig={wisetackProviderConfig}>
              <Typography variant="body1">
                Wisetack application status: {wisetackSignup?.status ?? 'unknown'}
              </Typography>
              <Typography variant="body1">Client signup link: {wisetackSignup?.signupLink ?? 'unknown'}</Typography>
            </BaseAdminProvider>
          )}
          <BaseAdminProvider title={'Workglue'} providerConfig={workglueProviderConfig}>
            <WorkGlueProviderForm
              providerName="workglue"
              isAdminIntegrations
              userId={userId}
              companyId={companyId}
              providerConfig={workglueProviderConfig}
              onChange={handleChange}
            />
          </BaseAdminProvider>
          {providers.xero && (
            <BaseAdminProvider title={'Xero'} providerConfig={xeroProviderConfig}>
              <XeroProviderForm
                providerName="xero"
                isAdminIntegrations
                providerConfig={xeroProviderConfig}
                onChange={handleChange}
              />
            </BaseAdminProvider>
          )}
          {providers.getbase && (
            <BaseAdminProvider title={'Zendesk'} providerConfig={{ ...zenDeskProviderConfig, enabled: true }}>
              <ZenDeskProviderForm
                providerName="getbase"
                isAdminIntegrations
                userId={userId}
                companyId={companyId}
                providerConfig={{ ...zenDeskProviderConfig, enabled: true }}
                onChange={handleChange}
              />
            </BaseAdminProvider>
          )}
        </Form>
      </UserProvider>
    </SettingsPage>
  )

  function handleChange(providerName: string, providerConfig: ProviderConfig, save: boolean) {
    if (providerName === 'pld') {
      handlePLDChange(providerConfig)
    } else {
      setValues({
        ...values,
        preferences: {
          ...values.preferences,
          integrations: {
            ...values.preferences.integrations,
            [providerName]: providerConfig
          }
        },
        options: {
          ...options,
          options: {
            ...options.options,
            integrations: {
              ...options?.options?.integrations,
              [providerName]: {
                ...(options?.options?.integrations?.[providerName] ?? {}),
                enabled: providerConfig.enabled
              }
            }
          }
        }
      })
    }

    if (save) {
      updateProvider(providerName)
    }
  }

  function handlePLDChange(providerConfig: ProviderConfig) {
    const newOptionsValues = updatePldConfig(providerConfig, options)

    const newValues = {
      ...values,
      options: {
        ...newOptionsValues
      }
    }

    setValues(newValues)
  }
}

export default UserAdminIntegrationsForm
