import { Box, Grid } from '@material-ui/core'
import {
  Button,
  Alert,
  FormikCheckbox,
  FormikHtmlEditor,
  FormSection,
  FormSectionTitle,
  Spinner,
  Typography,
  InputField,
  FormikInputField
} from '@ui/paintscout'
import { FastField, Field, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import type { ClientMetaDocument, OptionsDocument, QuoteDocument } from 'paintscout'
import React, { useState, useCallback, Suspense } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSaveClientQuoteMutation, useClientQuoteLazyQuery } from '@paintscout/api'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { useClientQuoteSearch } from 'shared/hooks/useClientQuoteSearch'
import { jsonToLucene } from 'json-to-lucene'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    rowGrid: {
      display: 'flex',
      flexDirection: 'row'
    },
    swapButton: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    quoteOptionDiv: {
      padding: theme.spacing(),
      marginTop: theme.spacing(),
      outline: '1px solid black',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }),
  { name: 'ClientGeneralForm' }
)

export interface ClientGeneralFormProps {
  classes?: StyleClasses<typeof useStyles>
}

// import CodeEditor from '../CodeEditor'
const CodeEditor = React.lazy(() => import('../CodeEditor'))

const ClientGeneralForm = (props: ClientGeneralFormProps) => {
  const classes = useStyles(props)
  const {
    values: { meta }
  } = useFormikContext<{ meta: ClientMetaDocument; options: OptionsDocument }>()
  const { enqueueSnackbar } = useSnackbar()
  const [quoteSearchType, setQuoteSearchType] = useState<'id' | 'number'>('id')
  const idSearch = quoteSearchType === 'id'
  const superadminEmail = `admin+${meta._id}@paintscout.com`

  function handleCopy() {
    enqueueSnackbar('Email Copied', { variant: 'success' })
  }

  const [searchId, setSearchId] = useState<string>('')
  const [searchNumber, setSearchNumber] = useState<string>('')
  const [quoteValue, setQuoteValue] = useState<string>('')
  const [updatedQuote, setUpdatedQuote] = useState<string>('')
  const [quoteOptions, setQuoteOptions] = useState<QuoteDocument[]>([])

  const [search] = useClientQuoteSearch({
    fetchPolicy: 'network-only',
    companyId: meta._id,
    onCompleted: (newData: any) => {
      if (!newData?.searchClientQuotes?.rawQuotes?.length) {
        enqueueSnackbar(`Unable to find QuoteNumber: ${searchNumber}`, { variant: 'error' })
        setUpdatedQuote('')
        setQuoteValue('')
      } else if (newData?.searchClientQuotes?.rawQuotes?.length > 1) {
        // Handle multiple quotes
        setQuoteOptions(newData.searchClientQuotes.rawQuotes)
      } else {
        // Single quote response
        enqueueSnackbar(`Found QuoteNumber: ${searchNumber}`, { variant: 'success' })
        const quote = JSON.stringify(newData?.searchClientQuotes.rawQuotes[0], null, '\t')
        setQuoteValue(quote)
        setUpdatedQuote(quote)
      }
    },
    onError: () => {
      enqueueSnackbar(`Unable to find QuoteNumber: ${searchNumber}`, { variant: 'error' })
      setUpdatedQuote('')
      setQuoteValue('')
    }
  })
  const [updateQuote, { loading: saving }] = useSaveClientQuoteMutation()
  const [getQuote, { loading: loadingQuote }] = useClientQuoteLazyQuery({
    onCompleted: (data) => {
      enqueueSnackbar(`Found QuoteID: ${searchId}`, { variant: 'success' })
      const quote = JSON.stringify(data.clientQuote, null, '\t')
      setQuoteValue(quote)
      setUpdatedQuote(quote)
    },
    onError: () => {
      if (searchId && updatedQuote !== searchId) {
        enqueueSnackbar(`Unable to find QuoteID: ${searchId}`, { variant: 'error' })
        setUpdatedQuote('')
        setQuoteValue('')
      }
    }
  })

  const handleUpdateQuote = useCallback(async (quote: string) => {
    try {
      const parsedQuote = JSON.parse(quote)
      const { __typename, ...cleanQuote } = parsedQuote
      const response = await updateQuote({
        variables: {
          quote: cleanQuote,
          companyId: meta._id
        }
      })
      enqueueSnackbar('Updated', { variant: 'success' })
      const saved = JSON.stringify(response.data.saveClientQuote, null, '\t')
      setQuoteValue(saved)
      setUpdatedQuote(saved)
    } catch (error) {
      console.error(error)
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line
  }, [])

  const handleQuoteSearch = async () => {
    setQuoteOptions([])
    setUpdatedQuote('')
    setQuoteValue('')
    if (quoteSearchType === 'id') {
      getQuote({ variables: { id: searchId, companyId: meta._id } })
    } else {
      const query =
        jsonToLucene({
          query: searchNumber ?? ''
        }) || '*:*'
      search({ query, companyId: meta._id })
    }
  }

  const handleSelectQuote = (quoteOption: QuoteDocument) => {
    const quote = JSON.stringify(quoteOption, null, '\t')
    setQuoteOptions([])
    setQuoteValue(quote)
    setUpdatedQuote(quote)
  }

  return (
    <>
      <FormSectionTitle title="General" />
      <FormSection>
        <Grid container spacing={3}>
          <Grid item className={classes.rowGrid} xs={12}>
            <Field label="Active" name="meta.active" confirmationCheck component={FormikCheckbox} />
            <Field label="Banned" name="meta.banned" confirmationCheck component={FormikCheckbox} />
            <Field label="Delete" name="meta.delete" confirmationCheck component={FormikCheckbox} />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography>
                <strong>Inactive:</strong> Can login, cannot create quotes. <strong>Banned:</strong> Cannot login.{' '}
                <strong>Delete:</strong> Can be purged entirely.
              </Typography>
              <Typography>
                <em>Only inactive clients will be deleted.</em>
              </Typography>
            </Alert>
          </Grid>
          <Grid item>
            <Field
              component={FormikInputField}
              name="meta.salesmateId"
              label={`Salesmate ID`}
              toolTip={"The Salesmate ID is the 4 or 5 digit number in a company's Salesmate URL: /app/companies/{id}/"}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Superadmin" />
      <FormSection>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Click the email below to copy
              <br />
              Or, right-click & open this link in a Private/Incognito Window!
            </Typography>
          </Grid>
          <CopyToClipboard text={superadminEmail} onCopy={handleCopy}>
            <Grid item xs={12}>
              <Typography variant={'h4'}>{superadminEmail}</Typography>
            </Grid>
          </CopyToClipboard>
          <Grid item xs={12}>
            <Button href={`${process.env.PS_APP_URL}/login/${superadminEmail}`}>Open PaintScout</Button>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Notes" />
      <FormSection>
        <FastField component={FormikHtmlEditor} debounce={true} name="meta.notes" style={{ width: '100%' }} />
      </FormSection>
      <FormSectionTitle title="Quote Editor" />
      <FormSection>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SwapHorizIcon
              className={classes.swapButton}
              onClick={() => setQuoteSearchType((quoteSearchType) => (quoteSearchType === 'id' ? 'number' : 'id'))}
            />
            <InputField
              fullWidth={true}
              label={idSearch ? 'Quote ID' : 'Quote Number'}
              name={idSearch ? 'quoteId' : 'quoteNumber'}
              value={idSearch ? searchId : searchNumber}
              onChange={(e) => (idSearch ? setSearchId(e.target.value) : setSearchNumber(e.target.value))}
              disabled={!meta.active}
            />
          </Grid>
          <Box margin={2} marginLeft={1.5}>
            <Button onClick={() => handleQuoteSearch()} loading={loadingQuote}>
              Search
            </Button>
          </Box>
          {updatedQuote && (
            <Box margin={2}>
              <CopyToClipboard
                text={updatedQuote}
                onCopy={() => enqueueSnackbar(`Copied to clipboard!`, { variant: 'success' })}
              >
                <Button onClick={() => {}} loading={saving}>
                  Copy JSON
                </Button>
              </CopyToClipboard>
            </Box>
          )}
        </Grid>
        <Suspense fallback={<Spinner />}>
          {updatedQuote && updatedQuote !== quoteValue && (
            <Box marginTop={1}>
              <Button onClick={() => handleUpdateQuote(updatedQuote)} loading={saving}>
                Update Quote
              </Button>
            </Box>
          )}
          {quoteOptions.length > 0 && (
            <Box>
              <Typography variant={'h5'}>Select Quote</Typography>
              {quoteOptions.map((quoteOption) => {
                return (
                  <div
                    key={quoteOption._id}
                    onClick={() => handleSelectQuote(quoteOption)}
                    className={classes.quoteOptionDiv}
                  >
                    <Typography variant={'h6'}>
                      Number: <Typography variant={'body2'}>{quoteOption.number}</Typography>
                    </Typography>
                    <Typography variant={'h6'}>
                      Id: <Typography variant={'body2'}>{quoteOption._id}</Typography>
                    </Typography>
                    <Typography variant={'h6'}>
                      Rev: <Typography variant={'body2'}>{quoteOption._rev}</Typography>
                    </Typography>
                    {quoteOption?.contact && (
                      <Typography variant={'h6'}>
                        Contact:{' '}
                        <Typography variant={'body2'}>
                          {quoteOption?.contact?.first_name ?? '*no firstname*'}{' '}
                          {quoteOption?.contact?.last_name ?? '*no lastname*'}
                        </Typography>
                      </Typography>
                    )}
                    <Typography variant={'h6'}>
                      Total: <Typography variant={'body2'}>{quoteOption?.totals?.after_tax}</Typography>
                    </Typography>
                  </div>
                )
              })}
            </Box>
          )}
          {updatedQuote && (
            <Box my={4}>
              <CodeEditor value={updatedQuote} onChange={setUpdatedQuote} />
            </Box>
          )}
          {updatedQuote && updatedQuote !== quoteValue && (
            <Button onClick={() => handleUpdateQuote(updatedQuote)} loading={saving}>
              Update Quote
            </Button>
          )}
        </Suspense>
      </FormSection>
    </>
  )
}

export default ClientGeneralForm
