/**
 * @module builder
 */
import type { OptionsDocument, RatesDocument } from 'paintscout'
import type { EditableRate } from '../../index'
import sortBy from 'lodash/sortBy'
import { getRateCaption } from './get-rate-caption'
import { convertCalculationType, convertRateType } from './rate-utils'

export function getRates(args: {
  options: OptionsDocument
  rates: RatesDocument
  inactive?: boolean
  quoteType?: string
  rateSection?: string
}): EditableRate[] {
  const { quoteType, rateSection, inactive, options } = args

  const { rates } = args.rates

  const ret = Object.keys(rates)
    .map((rateKey) => {
      const item = rates[rateKey]
      if (!item) {
        return null
      }

      const rate: EditableRate = {
        name: rateKey,
        custom: false,
        quoteType: item.int_ext as string,
        label: item.label,
        notes: item.notes,
        clientLabel: item.client_label,
        defaultApplications: item.default_applications,
        defaultWidth: item.default_width,
        defaultLength: item.default_length,
        defaultHeight: item.default_height,
        possibleApplications: item.possible_applications,
        values: item.values,
        coverage: String(item.coverage),
        rateType: convertRateType(item.rate_type),
        calculationType: convertCalculationType(item.calculation_type),
        order: item.order,
        rateSection: item.section,
        defaultProduct: item.default_product,
        defaultProducts: (item?.default_products ?? []).map((product) => ({
          ...product,
          coverage: String(product.coverage)
        })),
        prep: item.prep,
        useCoats: typeof item.useCoats !== 'undefined' ? item.useCoats : true,
        caption: getRateCaption({ options, rate: item }),
        reportText: item.report_text,
        areaReportText: item.areaReportText,
        hourlyRate: typeof item.hourlyRate === 'string' ? parseInt(item.hourlyRate, 10) : item.hourlyRate,
        overrideHourlyRate: !!item.overrideHourlyRate,
        useInches: !!item.useInches,
        workOrderOnly: !!item.workOrderOnly,
        hideQuantity: !!item.hideQuantity,
        hideQuantityOnWorkOrder: !!item.hideQuantityOnWorkOrder,
        showPrice: !!item.showPrice,
        showCoats: !!item.showCoats,
        showPrep: !!item.showPrep,
        showProduct: !!item.showProduct,
        excludeDiscount: !!item.excludeDiscount,
        excludeTax: !!item.excludeTax
      }

      if (!inactive && item.active !== true && typeof item.active !== 'undefined') {
        return null
      } else if (inactive) {
        rate.active = typeof item.active === 'undefined' ? true : !!item.active
      }

      if (quoteType !== item.quoteType && typeof quoteType !== 'undefined' && quoteType !== null) {
        return null
      }

      if (rateSection === rate.rateSection || typeof rateSection === 'undefined' || rateSection === null) {
        return rate
      }

      return null
    })
    .filter((item) => item)

  return sortBy(ret, 'order')
}
