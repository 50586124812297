// import { makeStyles } from '@material-ui/core'
// import type { Theme } from '@material-ui/core/styles'
import type { TableCellProps } from '@material-ui/core/TableCell'
import CheckIcon from '@material-ui/icons/Check'
import type { ResultsTableProps } from '@ui/paintscout'
import { ResultsTable, TableCell, TableRow, Typography, Link } from '@ui/paintscout'
import type { ClientMetaDocument } from 'paintscout'
import React, { useCallback } from 'react'
import moment from 'moment'
import { Hidden } from '@ui/paintscout'

export interface ClientSearchTableProps
  extends Omit<ResultsTableProps, 'rows' | 'columns' | 'classes' | 'page' | 'renderRow'> {
  clients: Partial<ClientMetaDocument>[]
  onClientClick?: (client: Partial<ClientMetaDocument>) => any
  onClientDeleted?: () => any
  onFetchNextPage: (nextPage: number) => any
  page: number
  sort?: string

  onRequestSort?: (ev: React.MouseEvent, name: string, direction: 'asc' | 'desc') => void
}

// const useStyles = makeStyles<Theme>((theme) => ({
//   tableCell: {
//     whiteSpace: 'nowrap'
//   }
// }))

export default function ClientSearchTable({
  clients,
  onFetchNextPage,
  onPageChange,
  onClientClick,
  onRequestSort,
  page,
  sort,
  ...other
}: ClientSearchTableProps) {
  const createSortHandler = (property: string) => (event: React.MouseEvent) => {
    if (onRequestSort) {
      // get current sort direction
      const currentSort = getSortDirection(property) || 'asc'

      // flip the sort direction
      const newSort = currentSort === 'asc' ? 'desc' : 'asc'

      onRequestSort(event, property, newSort)
    }
  }

  const getSortDirection = (property: string) => {
    const currentSortDirection = sort && sort.startsWith('-') ? 'desc' : 'asc'
    const currentSortName = sort && sort.startsWith('-') ? sort.slice(1) : sort

    if (currentSortName === property) {
      return currentSortDirection
    }
  }

  const renderRow = useCallback((row) => {
    // render cell as a link unless onClientClick is defined
    const cellProps = {
      row,
      disableLink: !!onClientClick,
      style: onClientClick ? { cursor: 'pointer' } : undefined,
      onClick: onClientClick
        ? () => {
            // prevent link navigation
            // e.preventDefault()

            onClientClick(row)
          }
        : null
    }

    const planType = getPlanType(row.plan)
    return (
      <TableRow key={row._id}>
        <TableCellLink {...cellProps}>
          <Typography variant={'body1'}>{row.name}</Typography>
          <Typography variant={'subtitle2'}>{row._id}</Typography>
        </TableCellLink>
        <Hidden smDown>
          <TableCellLink {...cellProps}>
            {row.city ? row.city + ', ' : ''} {row.province} {row.country}
          </TableCellLink>
        </Hidden>
        {/* <TableCellLink {...cellProps}>{row.phone}</TableCellLink> */}
        <TableCellLink {...cellProps}>{planType}</TableCellLink>
        <Hidden smDown>
          <TableCellLink {...cellProps}>
            {row.trialExpiry ? moment(row.trialExpiry).format('YYYY-MM-DD') : ''}
          </TableCellLink>
        </Hidden>
        {/* <TableCellLink align='center' {...cellProps}>{row.userCount}</TableCellLink> */}
        <TableCellLink align="center" {...cellProps}>
          {row.userCounts?.all ?? ''}
        </TableCellLink>
        <TableCellLink align="center" {...cellProps}>
          {row.aggregates?.totalQuotes ?? ''}
        </TableCellLink>
        <TableCellLink align="center" {...cellProps}>
          {row.aggregates?.totalInvoices ?? ''}
        </TableCellLink>
        <TableCellLink align="center" {...cellProps}>
          {row.active ? <CheckIcon /> : null}
        </TableCellLink>
        <TableCellLink align="center" {...cellProps}>
          {row.delete ? <CheckIcon /> : null}
        </TableCellLink>
      </TableRow>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResultsTable
      rows={clients || []}
      noResultsMessage="No results"
      page={page}
      columns={
        <TableRow>
          <TableCell>
            Name
            {/* <Checkbox checked={hideNameless} onChange={(e) => setHideNameless(e.target.checked)} /> */}
            {/* <Typography variant={'body2'}>Hide Nameless?</Typography> */}
          </TableCell>
          <Hidden smDown>
            <TableCell>Location</TableCell>
          </Hidden>
          {/* <TableCell>Phone</TableCell> */}
          <TableCell>Plan</TableCell>
          <Hidden smDown>
            <TableCell
              sortable={!!sort}
              sortDirection={getSortDirection('trialExpiry')}
              onClick={createSortHandler('trialExpiry')}
            >
              Trial Exp.
            </TableCell>
          </Hidden>
          <TableCell
            sortable={!!sort}
            sortDirection={getSortDirection('userCounts.all')}
            onClick={createSortHandler('userCounts.all')}
          >
            User #
          </TableCell>
          <TableCell>Quote #</TableCell>
          <TableCell>Invoice #</TableCell>
          <TableCell>Active</TableCell>
          <TableCell>Delete</TableCell>
        </TableRow>
      }
      renderRow={renderRow}
      onPageChange={(nextPage) => {
        if (nextPage > page && clients.length < other.rowsPerPage * nextPage) {
          onFetchNextPage(nextPage)
        }

        if (onPageChange) {
          onPageChange(nextPage)
        }
      }}
      {...other}
    />
  )
}

function TableCellLink({
  row,
  disableLink,
  ...other
}: { row: Partial<ClientMetaDocument>; disableLink?: boolean } & TableCellProps) {
  return (
    <TableCell
      component={
        !disableLink
          ? (cProps: any) => <Link to={{ pathname: `/clients/${encodeURIComponent(row._id)}` }} {...cProps} />
          : undefined
      }
      {...other}
    />
  )
}

function getPlanType(plan: string): string {
  let planType = ''
  switch (plan) {
    case 'trial':
      planType = 'Trial'
      break
    case 'business-annual':
      planType = 'Business - Annual'
      break
    case 'business-monthly':
      planType = 'Business - Monthly'
      break
    case 'premium-annual':
      planType = 'Premium - Annual'
      break
    case 'premium-monthly':
      planType = 'Premium - Monthly'
      break
    case 'child':
      planType = 'Child'
      break
    case 'non-billing':
      planType = 'Non-Billing'
      break
    case 'custom':
      planType = 'Custom'
      break
  }
  return planType
}
