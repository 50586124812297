import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Checkbox, Collapse, FormControlLabel, Grid, Switch, Typography } from '@ui/paintscout'
import type { PresentationOption } from 'paintscout'
import type { PartnerPresentationOptions, PartnerContentFeatures } from 'paintscout'
import type { PartnerStrings } from '../PresentationPartnerDialog'

const useStyles = makeStyles<Theme, SherwinWilliamsDialogSectionProps>(
  (_theme) => ({
    root: {},
    pageLabel: {
      paddingTop: '12px'
    },
    pagesContainer: {
      padding: '20px'
    },
    sectionsContainer: {
      padding: '0 10px'
    }
  }),
  { name: 'SherwinWilliamsDialogSection' }
)

export interface SherwinWilliamsDialogSectionProps {
  classes?: StyleClasses<typeof useStyles>
  partnerContentFeatures: PartnerContentFeatures
  handlePartnerEnableOnChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    partner: PartnerStrings
  ) => void
  partnerContentEnabled: PartnerPresentationOptions
  presentation: PresentationOption
}

function SherwinWilliamsDialogSection(props: SherwinWilliamsDialogSectionProps) {
  const { partnerContentFeatures, handlePartnerEnableOnChange, partnerContentEnabled, presentation } = props

  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      {partnerContentFeatures?.sherwinWilliams?.presentationPages?.enabled && (
        <>
          <FormControlLabel
            label={'Sherwin Williams'}
            name={'enabled'}
            control={
              <Switch
                onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                checked={partnerContentEnabled?.sherwinWilliams?.enabled}
              />
            }
          />
          <Collapse show={partnerContentEnabled?.sherwinWilliams?.enabled}>
            <Grid container direction="column" className={classes.pagesContainer}>
              <Grid item>
                <Typography variant="h4">Pages</Typography>
              </Grid>

              <FormControlLabel
                label={'Most Popular Colors'}
                name={'pages.popularColors.enabled'}
                className={classes.pageLabel}
                control={
                  <Switch
                    onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                    checked={partnerContentEnabled?.sherwinWilliams?.pages?.popularColors?.enabled}
                  />
                }
              />
              <Collapse show={partnerContentEnabled?.sherwinWilliams?.pages?.popularColors?.enabled}>
                <Grid item container direction="column">
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Color of the Year'}
                      control={
                        <Checkbox
                          name={'pages.popularColors.colorOfTheYear'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
                              ?.colorOfTheYear
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  {/* <Grid item className={classes.sectionsContainer}>
                      <FormControlLabel
                        label={'Colors of the Month'}
                        control={
                          <Checkbox
                            name={'pages.popularColors.colorsOfTheMonth'}
                            checked={
                              presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
                                ?.colorsOfTheMonth
                            }
                            onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                          />
                        }
                      />
                    </Grid> */}
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Top Colors'}
                      control={
                        <Checkbox
                          name={'pages.popularColors.topColors'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors?.topColors
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Finest Whites & Neutrals'}
                      control={
                        <Checkbox
                          name={'pages.popularColors.finestWhitesNeutrals'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
                              ?.finestWhitesNeutrals
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Expert Collections'}
                      control={
                        <Checkbox
                          name={'pages.popularColors.expertCollections'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
                              ?.expertCollections
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Explore Exterior Paint'}
                      control={
                        <Checkbox
                          name={'pages.popularColors.exploreExteriorPaint'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.popularColors
                              ?.exploreExteriorPaint
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>

              <FormControlLabel
                label={'Free Color Samples'}
                name={'pages.colorSamples.enabled'}
                className={classes.pageLabel}
                control={
                  <Switch
                    onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                    checked={partnerContentEnabled?.sherwinWilliams?.pages?.colorSamples?.enabled}
                  />
                }
              />
              <Collapse show={partnerContentEnabled?.sherwinWilliams?.pages?.colorSamples?.enabled}>
                <Grid item container direction="column">
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Right to Your Door'}
                      control={
                        <Checkbox
                          name={'pages.colorSamples.rightToYourDoor'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples
                              ?.rightToYourDoor
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Hottest Colors'}
                      control={
                        <Checkbox
                          name={'pages.colorSamples.topHottestColors'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples
                              ?.topHottestColors
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Finest Whites'}
                      control={
                        <Checkbox
                          name={'pages.colorSamples.finestWhites'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples?.finestWhites
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Peel and Stick'}
                      control={
                        <Checkbox
                          name={'pages.colorSamples.peelAndStick'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorSamples?.peelAndStick
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>

              <FormControlLabel
                label={'Help With Color'}
                name={'pages.colorHelp.enabled'}
                className={classes.pageLabel}
                control={
                  <Switch
                    onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                    checked={partnerContentEnabled?.sherwinWilliams?.pages?.colorHelp?.enabled}
                  />
                }
              />
              <Collapse show={partnerContentEnabled?.sherwinWilliams?.pages?.colorHelp?.enabled}>
                <Grid item container direction="column">
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Get Color Help'}
                      control={
                        <Checkbox
                          name={'pages.colorHelp.getColorHelp'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp?.getColorHelp
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Upload Your Photos'}
                      control={
                        <Checkbox
                          name={'pages.colorHelp.uploadPhotos'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp?.uploadPhotos
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Schedule Free Consultation'}
                      control={
                        <Checkbox
                          name={'pages.colorHelp.scheduleFreeConsultation'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp
                              ?.scheduleFreeConsultation
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Match A Photo'}
                      control={
                        <Checkbox
                          name={'pages.colorHelp.matchAPhoto'}
                          checked={
                            presentation?.advanced?.partnerContent?.sherwinWilliams?.pages?.colorHelp?.matchAPhoto
                          }
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'sherwinWilliams')}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Collapse>
        </>
      )}
    </div>
  )
}

export default SherwinWilliamsDialogSection
