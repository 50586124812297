import { useClientQuery } from '@paintscout/api'
import type { DialogProps } from '@ui/paintscout'
import { Button, Checkbox, FormControlLabel, FormSection, FormSectionTitle, Grid, DropdownSelect } from '@ui/paintscout'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@ui/paintscout'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import type { ClientImportExportProps } from '../ClientAdministrationForm/ClientImportExport'
import type { DropdownSelectOption } from '@paintscout/ui/src/DropdownSelect'

const defaultImportOptions = {
  quoteTypes: false,
  areaLabels: false,
  lineItems: false,
  products: false,
  rates: false,
  presentations: false,
  terms: false,
  disclaimers: false,
  quoteEmails: false,
  customEmailTemplates: false,
  thankYouAcceptEmails: false,
  thankYouDeclineEmails: false,
  paymentReceivedEmail: false,
  followUps: false
}

const accountOptions = [
  { value: 'dhjlwwbvfyploard', label: 'Onboarding Account' },
  { value: 'iqxvskejjohghvzh', label: 'Consulting 4 Contractors' },
  { value: 'oxjbyvmmctvnheho', label: 'PBP TEMPLATE' },
  { value: 'imgnzzxxwlujqesx', label: 'PCA TEMPLATE' },
  { value: 'bcqjokvasggitxfx', label: 'SUMMIT TEMPLATE' },
  { value: 'sqlwadwqemrpelag', label: 'Unforgettable Coatings' }
]

export interface ImportAccountDialogProps extends DialogProps {
  queueDocUpdates: (json: any, selectedOptions?: ClientImportExportProps) => void
  onClose?: () => void
}

const ImportAccountDialog = ({ onClose, queueDocUpdates, ...props }: ImportAccountDialogProps) => {
  const match = useRouteMatch<{ id: string; tab: string; section: string }>()
  const { id } = match.params
  const { enqueueSnackbar } = useSnackbar()
  const [importOptions, setImportOptions] = useState(defaultImportOptions)
  const [selectedAccount, setSelectedAccount] = useState(id)
  const { data, error } = useClientQuery({
    variables: {
      id: selectedAccount
    }
  })

  if (error) {
    enqueueSnackbar('Error retrieving account details', { variant: 'error' })
    return null
  }
  const details = data?.client

  const handleImportCheckboxChange = (target: string) => {
    return function () {
      setImportOptions({ ...importOptions, [target]: !importOptions[target] })
    }
  }

  const handleImport = () => {
    if (details) {
      queueDocUpdates(details, importOptions)
      enqueueSnackbar('Documents ready to save!', { variant: 'success' })
      onClose()
    } else {
      enqueueSnackbar('Error retrieving account details', { variant: 'error' })
    }
  }

  return (
    <Dialog {...props}>
      <DialogTitle>Import From Account</DialogTitle>
      <DialogContent>
        <DropdownSelect
          label="Template Account"
          variant="single"
          options={accountOptions}
          searchable
          value={accountOptions.find((option) => option.value === selectedAccount)}
          onChange={(option: DropdownSelectOption) => {
            setSelectedAccount(option.value)
          }}
          onCreateOption={(option: string) => {
            accountOptions.push({ value: option, label: option })
            setSelectedAccount(option)
          }}
        />
        <br />
        <br />
        <FormSectionTitle title="Import" />
        <FormSection>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12}>
              <FormControlLabel
                label={'Quote Types'}
                control={
                  <Checkbox checked={importOptions.quoteTypes} onChange={handleImportCheckboxChange('quoteTypes')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Area Labels'}
                control={
                  <Checkbox checked={importOptions.areaLabels} onChange={handleImportCheckboxChange('areaLabels')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Line Items'}
                control={
                  <Checkbox checked={importOptions.lineItems} onChange={handleImportCheckboxChange('lineItems')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Products'}
                control={
                  <Checkbox checked={importOptions.products} onChange={handleImportCheckboxChange('products')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Rates'}
                control={<Checkbox checked={importOptions.rates} onChange={handleImportCheckboxChange('rates')} />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Presentations'}
                control={
                  <Checkbox
                    checked={importOptions.presentations}
                    onChange={handleImportCheckboxChange('presentations')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Terms'}
                control={<Checkbox checked={importOptions.terms} onChange={handleImportCheckboxChange('terms')} />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Disclaimers'}
                control={
                  <Checkbox checked={importOptions.disclaimers} onChange={handleImportCheckboxChange('disclaimers')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Quote Emails'}
                control={
                  <Checkbox checked={importOptions.quoteEmails} onChange={handleImportCheckboxChange('quoteEmails')} />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Custom Email Templates'}
                control={
                  <Checkbox
                    checked={importOptions.customEmailTemplates}
                    onChange={handleImportCheckboxChange('customEmailTemplates')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Thank You Accept Emails'}
                control={
                  <Checkbox
                    checked={importOptions.thankYouAcceptEmails}
                    onChange={handleImportCheckboxChange('thankYouAcceptEmails')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Thank You Decline Emails'}
                control={
                  <Checkbox
                    checked={importOptions.thankYouDeclineEmails}
                    onChange={handleImportCheckboxChange('thankYouDeclineEmails')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={'Payment Received Email'}
                control={
                  <Checkbox
                    checked={importOptions.paymentReceivedEmail}
                    onChange={handleImportCheckboxChange('paymentReceivedEmail')}
                  />
                }
              />
            </Grid>
          </Grid>
        </FormSection>
      </DialogContent>
      <DialogActions leftButton={<Button onClick={onClose}>Cancel</Button>}>
        <Button onClick={handleImport} disabled={selectedAccount === id}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportAccountDialog
