import {
  Button,
  // Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type DialogProps
} from '@ui/paintscout'
import { createStyles, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import type { PartnerContentFeatures, PartnerPresentationOptions, PresentationOption } from 'paintscout'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { SherwinWilliamsDialogSection, BenjaminMooreDialogSection } from './PartnerDialogSections'

export interface PresentationPartnerDialogProps
  extends Omit<DialogProps, 'message' | 'title' | 'loading' | 'onChange'> {
  onConfirm?: (updatedPresentation: PresentationOption) => void
  onCancel?: () => void
  presentation: PresentationOption
  partnerContentFeatures: PartnerContentFeatures
}

export const styles = () =>
  createStyles({
    root: {}
  })

export type PartnerStrings = 'sherwinWilliams' | 'benjaminMoore'

function PresentationPartnerDialog({
  open,
  onConfirm,
  onCancel,
  partnerContentFeatures,
  presentation,
  ...props
}: PresentationPartnerDialogProps) {
  // const { classes } = props

  const [partnerContentEnabled, setPartnerContentEnabled] = useState<PartnerPresentationOptions>(
    presentation?.advanced?.partnerContent ?? {
      sherwinWilliams: {},
      benjaminMoore: {}
    }
  )

  const handlePartnerEnableOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    partner: PartnerStrings
  ) => {
    const newPartnerContentEnabled = cloneDeep(partnerContentEnabled)
    set(newPartnerContentEnabled, [partner, ...event.target.name.split('.')], checked)
    setPartnerContentEnabled(newPartnerContentEnabled)
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm({
        ...presentation,
        advanced: {
          ...presentation.advanced,
          partnerContent: partnerContentEnabled
        }
      })
    }
  }

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Partner Presentation Pages</DialogTitle>
      <DialogContent>
        <SherwinWilliamsDialogSection
          partnerContentFeatures={partnerContentFeatures}
          handlePartnerEnableOnChange={handlePartnerEnableOnChange}
          partnerContentEnabled={partnerContentEnabled}
          presentation={presentation}
        />
        <BenjaminMooreDialogSection
          partnerContentFeatures={partnerContentFeatures}
          handlePartnerEnableOnChange={handlePartnerEnableOnChange}
          partnerContentEnabled={partnerContentEnabled}
          presentation={presentation}
        />
      </DialogContent>
      <DialogActions
        leftButton={
          <Button variant="text" onClick={onCancel}>
            Cancel
          </Button>
        }
      >
        <Button onClick={handleConfirm}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(PresentationPartnerDialog)
