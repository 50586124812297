import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { DialogProps } from '@ui/paintscout'
import type { StyleClasses } from '@ui/core/theme'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Alert,
  Switch,
  FormLabel,
  EditableDialogTitle,
  UploadImage,
  Grid,
  InputField,
  Tooltip,
  useClientOptions,
  PhoneNumberInput,
  useUser,
  DropdownSelect
} from '@ui/paintscout'
import type { QuoteTypeOption, TermsOption } from '@paintscout/util/builder'
import {
  getObjectLabels,
  getPresentationOptions,
  getTermsOptions,
  updateQuoteTypeOption,
  getFeatures
} from '@paintscout/util/builder'
import Done from '@material-ui/icons/Done'
import get from 'lodash/get'
import find from 'lodash/find'
import type { OptionsDocument, PresentationOption } from 'paintscout'
import type { DropdownSelectOption } from '@ui/paintscout/src/DropdownSelect'

const useStyles = makeStyles<Theme, EditQuoteTypeOptionDialogProps>(
  (theme) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(2)
    },
    logo: {
      width: '100%',
      height: theme.spacing(8),
      paddingBottom: theme.spacing(4)
    },
    warningMessage: {
      marginBottom: theme.spacing(4)
    }
  }),
  { name: 'EditQuoteTypeOptionDialog' }
)

export interface EditQuoteTypeOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  item: QuoteTypeOption
  options: OptionsDocument

  onConfirm: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel: () => void
}

function EditQuoteTypeOptionDialog(props: EditQuoteTypeOptionDialogProps) {
  const classes = useStyles(props)
  const { item: originalItem, options, onCancel, ...baseDialogProps } = props
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [item, setItem] = useState<QuoteTypeOption>({
    hourlyRate: null,
    ...originalItem
  })
  const [invalidLabel, setInvalidLabel] = useState<boolean>(originalItem.label.includes('/'))
  const { isAdmin } = useClientOptions()
  const { isAdmin: isAdminUser, isSuperadmin } = useUser()
  const features = getFeatures({ options })
  const hasPresentations = get(features, 'presentation.enabled', false)
  const quoteTypeCompanyOverrideFeat = true // get(features, 'quotes.quoteTypeCompanyOverride', false)
  const objectLabels = getObjectLabels({ options })

  // const logoSrc = fileSrc || get(item, 'companyOptions.logo.value', null)
  const cloudinaryId = get(item, 'companyOptions.logo.cloudinaryId', null)
  const s3PublicKey = get(item, 'companyOptions.logo.s3PublicKey', null)
  const src = get(item, 'companyOptions.logo.src', null)

  const termsOptions = getTermsOptions({ options })
  const selectedTermsOption: TermsOption = find(termsOptions, { name: item.defaultTerms })

  const presentationOptions = getPresentationOptions({ options })
  const selectedPresentationOption = find(presentationOptions, {
    key: item.defaultPresentation
  }) as PresentationOption

  const areaTypeOptions = [
    { label: 'Room', value: 'room' },
    { label: 'Surface', value: 'surface' }
  ]
  const selectedAreaTypeOption: DropdownSelectOption = find(areaTypeOptions, { value: item.defaultAreaType })
  const selectedAreaTypeOptionValue = selectedAreaTypeOption
    ? { label: selectedAreaTypeOption.label, value: selectedAreaTypeOption.value }
    : null

  const orientationOptions: DropdownSelectOption[] = [
    { value: 'auto', label: 'Auto (Choose best for logo size)' },
    { value: 'default', label: 'Default (Side-by-Side)' },
    { value: 'stack', label: 'Stack (Put logo above company info)' }
  ]
  const logoOrientation = find(orientationOptions, { value: item.companyOptions?.logo?.orientation })

  const handleChange = (event: any) => {
    const { name, value } = event.target

    setItem((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setInvalidLabel((name === 'label' && value.includes('/')) || (name !== 'label' && item['label'].includes('/')))
    setIsDirty(true)
  }

  const handleCompanyOptionsChange = (event: any) => {
    const { name, value } = event.target

    setItem((item) => ({
      ...item,
      companyOptions: {
        ...item.companyOptions,
        [name]: value
      }
    }))
    setIsDirty(true)
  }

  const handleLogoOrientationChange = (value: DropdownSelectOption) => {
    setItem((item) => ({
      ...item,
      companyOptions: {
        ...item.companyOptions,
        logo: {
          ...item.companyOptions.logo,
          orientation: value.value
        }
      }
    }))
    setIsDirty(true)
  }

  const toggleOverride = (_event) => {
    setItem((prevState) => ({
      ...prevState,
      overrideCompanyOptions: !prevState.overrideCompanyOptions
    }))
    setIsDirty(true)
  }

  const onUploadLogo = async (args: {
    src: string
    cloudinaryPublicId: string
    s3PublicKey: string
    format: string
    width: number
    height: number
  }) => {
    setItem((prevState) => ({
      ...prevState,
      companyOptions: {
        ...prevState.companyOptions,
        logo: {
          cloudinaryId: args?.cloudinaryPublicId ?? '',
          s3PublicKey: args?.s3PublicKey ?? '',
          value: args.src,
          fileType: args.format,
          width: args.width,
          height: args.height,
          orientation: prevState?.companyOptions?.logo?.orientation ?? 'auto'
        }
      }
    }))
    setIsDirty(true)
  }

  const onClearLogo = () => {
    setItem((prevState) => ({
      ...prevState,
      companyOptions: {
        ...prevState.companyOptions,
        logo: {
          cloudinaryId: '',
          s3PublicKey: '',
          value: '',
          fileType: '',
          width: null,
          height: null
        }
      }
    }))
    setIsDirty(true)
  }

  const handleTermsChange = (option: DropdownSelectOption) => {
    setItem((prevState) => ({
      ...prevState,
      defaultTerms: option.value
    }))
    setIsDirty(true)
  }

  const handlePresentationChange = (option: DropdownSelectOption) => {
    setItem((prevState) => ({
      ...prevState,
      defaultPresentation: option.value
    }))
    setIsDirty(true)
  }

  const handleAreaTypeChange = (option: DropdownSelectOption) => {
    setItem((prevState) => ({
      ...prevState,
      defaultAreaType: option.value
    }))
    setIsDirty(true)
  }

  const handleConfirm = (_event) => {
    if (props.onConfirm) {
      const { options } = props
      const updatedOptions = updateQuoteTypeOption({ options, quoteTypeOption: item })
      props.onConfirm(updatedOptions, isDirty)
    }
  }

  const handleToggle = (_event, _checked) => {
    setItem((prevState) => ({
      ...prevState,
      active: !prevState.active
    }))
    setIsDirty(true)
  }

  const leftButton = (
    <Button className={classes.cancel} variant={'text'} onClick={onCancel}>
      Cancel
    </Button>
  )

  const allMessage =
    item.key === 'all' ? (
      <Alert
        severity="info"
        content={
          <>
            The <strong>All Types</strong> {objectLabels.quote.value.toLowerCase()} type is a default option you can use
            when building {objectLabels.quote.plural.toLowerCase()} that have more than one type of service.{' '}
            {objectLabels.quote.plural} made with this type will have the ability to select all items available in all
            other {objectLabels.quote.value.toLowerCase()} types.
          </>
        }
        className={classes.warningMessage}
      />
    ) : null

  return (
    <Dialog fullWidth={true} maxWidth={'md'} {...baseDialogProps}>
      <EditableDialogTitle
        title={`Edit ${objectLabels.quote.value} Type`}
        onToggle={handleToggle}
        toggleValue={typeof item.active !== 'undefined' ? !!item.active : true}
        toggleLabel="Active"
      />
      <DialogContent>
        {allMessage}
        <form
          id="quote-type-option-form"
          className={classes.root}
          onSubmit={(e) => {
            e.preventDefault()
            handleConfirm(e)
          }}
        >
          <InputField
            label={'Label'}
            name={'label'}
            value={item.label}
            fullWidth={true}
            onChange={handleChange}
            disabled={item.key === 'all'}
            required
            error={!item.label || invalidLabel}
            helperText={invalidLabel ? 'Label cannot contain "/"' : ''}
          />
          <InputField
            label={'Hourly Rate'}
            sublabel={'Leave blank for default'}
            name={'hourlyRate'}
            format={'rate'}
            value={item.hourlyRate}
            fullWidth={true}
            onChange={handleChange}
          />
          <DropdownSelect
            variant="single"
            label={'Default Area Type'}
            name={'defaultTerms'}
            value={selectedAreaTypeOptionValue}
            onChange={handleAreaTypeChange}
            options={areaTypeOptions}
            fullWidth={true}
          />
          {hasPresentations && (
            <DropdownSelect
              variant="single"
              label={'Default Presentation'}
              name={'defaultPresentation'}
              value={
                selectedPresentationOption
                  ? { value: selectedPresentationOption.key, label: selectedPresentationOption.label }
                  : null
              }
              onChange={handlePresentationChange}
              options={presentationOptions.map((option) => ({
                label: option.label,
                value: option.key
              }))}
              fullWidth={true}
            />
          )}
          <DropdownSelect
            variant="single"
            label={'Default Terms'}
            name={'defaultTerms'}
            value={selectedTermsOption ? { value: selectedTermsOption.name, label: selectedTermsOption.label } : null}
            onChange={handleTermsChange}
            options={termsOptions.map((option) => ({
              label: option.label,
              value: option.name
            }))}
            fullWidth={true}
          />
          {(isAdmin || isSuperadmin || (quoteTypeCompanyOverrideFeat && isAdminUser)) && (
            <Grid container={true} spacing={3} justifyContent="flex-start" alignItems="flex-start">
              <Grid item={true} xs={12} sm={12} md={12}>
                <Tooltip
                  content={
                    'You can override your company options to display different info on this quote type. Leave values blank to use your default Settings.'
                  }
                  title={'Override Company Options'}
                >
                  <Typography variant={'h4'} onClick={toggleOverride}>
                    Override Company Options
                    <Switch checked={!!item.overrideCompanyOptions} />
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          {item.overrideCompanyOptions && (
            <Grid container={true} spacing={3} justifyContent="flex-start" alignItems="flex-start">
              <Grid item={true} xs={12} sm={12} md={12}>
                <FormLabel>Company Logo</FormLabel>
                <UploadImage
                  cloudinaryPublicId={cloudinaryId}
                  s3PublicKey={s3PublicKey}
                  src={src}
                  onUpload={onUploadLogo}
                  onClear={onClearLogo}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect
                  variant="single"
                  label={`Logo Orientation`}
                  margin="dense"
                  value={logoOrientation}
                  options={orientationOptions}
                  required
                  error={!logoOrientation}
                  fullWidth
                  onChange={handleLogoOrientationChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  label={'Company Name'}
                  name={'name'}
                  value={item.companyOptions.name}
                  fullWidth={true}
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  label={'Phone Number'}
                  name={'phoneNumber'}
                  value={item.companyOptions.phoneNumber}
                  inputComponent={PhoneNumberInput}
                  inputProps={{ separator: options?.options?.phoneNumbers?.separator }}
                  fullWidth={true}
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  label={'City'}
                  name={'city'}
                  value={item.companyOptions.city}
                  fullWidth={true}
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  label={'Address'}
                  name={'address'}
                  value={item.companyOptions.address}
                  fullWidth={true}
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  label={'State/Province'}
                  name={'province'}
                  value={item.companyOptions.province}
                  fullWidth={true}
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <InputField
                  label={'Zip/Postal Code'}
                  name={'postal'}
                  value={item.companyOptions.postal}
                  fullWidth={true}
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
              <Grid item={true} xs={12} sm={12} md={12}>
                <InputField
                  label={'Additional Information'}
                  name={'additional'}
                  value={item.companyOptions.additional}
                  fullWidth={true}
                  multiline
                  onChange={handleCompanyOptionsChange}
                />
              </Grid>
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions showBorder={true} leftButton={leftButton}>
        <Button type="submit" form="quote-type-option-form" disabled={invalidLabel} variant={'contained'} icon={Done}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditQuoteTypeOptionDialog
