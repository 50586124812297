import {
  Button,
  Grid,
  DialogStackContext,
  ExportJsonDialog,
  FormSection,
  FormSectionTitle,
  FormControlLabel,
  ImportJsonDialog,
  Checkbox,
  SplitMenuButton,
  MenuItem,
  Typography,
  ConfirmationDialog,
  Select,
  DateRangePicker,
  TaskConfirmationDialog
} from '@ui/paintscout'
import SearchClientsDialog from '../SearchClientsDialog'
import { useFormikContext } from 'formik'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import type { OptionsDocument, RatesDocument, ClientMetaDocument } from 'paintscout'
import ImportAccountDialog from '../ImportAccountDialog'
import React, { useCallback, useContext, useState, useEffect } from 'react'
import { cleanOptions, cleanRates, getFeature } from '@paintscout/util/builder'
import { useApolloClient } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import ImportCSVDialog from '../ImportCSVDialog'
import {
  useSaveClientQuoteMutation,
  useTransferClientDocsMutation,
  useDeleteClientDocsMutation,
  useResizeQuoteImagesMutation,
  useMigrateCompanyImagesMutation
} from '@paintscout/api'

import { checkImportedCSV, downloadCSVTemplate } from './util'
import AlertDialog from '@ui/paintscout/src/dialogs/AlertDialog'
import find from 'lodash/find'
import { selectOptions } from '../UserAdministrationForm/UserImportExport'
import type { OptionItem } from '../UserAdministrationForm/UserImportExport'
import { uploadFile } from '@paintscout/util/s3'
import type { CsvOwner } from '@paintscout/util/s3'
import moment from 'moment'

export interface ClientImportExportProps {
  quoteTypes: boolean
  areaLabels: boolean
  lineItems: boolean
  products: boolean
  rates: boolean
  presentations: boolean
  terms: boolean
  disclaimers: boolean
  quoteEmails: boolean
  customEmailTemplates: boolean
  thankYouAcceptEmails: boolean
  thankYouDeclineEmails: boolean
  paymentReceivedEmail: boolean
  followUps: boolean
}

const ClientImportExport = () => {
  const { openDialog, dismissDialog, dismissAllDialogs } = useContext(DialogStackContext)
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
    meta: ClientMetaDocument
  }>()
  const { options, rates, meta } = values
  const { enqueueSnackbar } = useSnackbar()
  const apolloClient = useApolloClient()
  const defaultExportOptions = {
    quoteTypes: false,
    areaLabels: false,
    lineItems: false,
    products: false,
    rates: false,
    presentations: false,
    terms: false,
    disclaimers: false,
    quoteEmails: false,
    customEmailTemplates: false,
    thankYouAcceptEmails: false,
    thankYouDeclineEmails: false,
    paymentReceivedEmail: false,
    followUps: false
  } as ClientImportExportProps

  const [exportOptions, setExportOptions] = useState(defaultExportOptions)
  const [isImporting, setIsImporting] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [updateQuote] = useSaveClientQuoteMutation()
  const [newClient, setNewClient] = useState<Partial<ClientMetaDocument>>(null)
  const [transferClientDocs] = useTransferClientDocsMutation()
  const [resizeQuoteImages] = useResizeQuoteImagesMutation()
  const [migrateCompanyImages] = useMigrateCompanyImagesMutation()
  const [deleteClientDocs] = useDeleteClientDocsMutation()
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [deleteOption, setDeleteOption] = useState<string>('')
  const [resizeLoading, setResizeLoading] = useState<boolean>(false)

  const [dates, setDates] = useState<{ dateFrom: string; dateTo: string }>({
    dateFrom: '',
    dateTo: ''
  })

  function handleExport() {
    const emailsExported =
      exportOptions.quoteEmails ||
      exportOptions.customEmailTemplates ||
      exportOptions.followUps ||
      exportOptions.thankYouAcceptEmails ||
      exportOptions.thankYouDeclineEmails ||
      exportOptions.paymentReceivedEmail
    const emailValuesExported =
      exportOptions.quoteEmails ||
      exportOptions.thankYouAcceptEmails ||
      exportOptions.thankYouDeclineEmails ||
      exportOptions.paymentReceivedEmail
    openDialog(ExportJsonDialog, {
      item: {
        options: {
          ...(exportOptions.quoteTypes && { intExtOptions: options.options.intExtOptions }),
          ...(exportOptions.areaLabels && { areaTemplates: options.options.areaTemplates }),
          ...(exportOptions.lineItems && { lineItems: options.options.lineItems }),
          ...(exportOptions.products && { products: options.options.products }),
          ...(exportOptions.rates && { rateSections: options.options.rateSections }),
          ...(exportOptions.presentations && { presentationOptions: options.options.presentationOptions }),
          ...(exportOptions.terms && { terms: options.options.terms }),
          ...(exportOptions.disclaimers && {
            acceptDisclaimer: options?.options?.acceptDisclaimer ?? '',
            acceptAdditionalDisclaimer: options?.options?.acceptAdditionalDisclaimer ?? '',
            declineDisclaimer: options?.options?.declineDisclaimer ?? ''
          }),
          ...(emailsExported && {
            emails: {
              ...(emailValuesExported && {
                values: {
                  ...(exportOptions.thankYouAcceptEmails && {
                    'accept-thank-you': options.options.emails.values['accept-thank-you']
                  }),
                  ...(exportOptions.thankYouDeclineEmails && {
                    'decline-thank-you': options.options.emails.values['decline-thank-you']
                  }),
                  ...(exportOptions.paymentReceivedEmail && {
                    'payment-received': options.options.emails.values['payment-received']
                  }),
                  ...(exportOptions.quoteEmails && {
                    'send-invoice-client': options.options.emails.values['send-invoice-client'],
                    'send-quote-client': options.options.emails.values['send-quote-client'],
                    'send-work-order-staff': options.options.emails.values['send-work-order-staff']
                  })
                }
              }),
              ...(exportOptions.customEmailTemplates && {
                customTemplates: options?.options?.emails?.customTemplates ?? { order: [], values: {} }
              }),
              ...(exportOptions.followUps && {
                followUps: options?.options?.emails?.followUps ?? { order: [], values: {} }
              })
            }
          })
        },
        ...(exportOptions.rates && { rates: rates.rates })
      },
      onConfirm: dismissDialog
    })
  }

  function handleConfirmImport({
    newClient,
    overwrite
  }: {
    newClient: Partial<ClientMetaDocument>
    overwrite: boolean
  }) {
    openDialog(ConfirmationDialog, {
      message: 'Are you sure?',
      yesLabel: 'Confirm',
      noLabel: 'No, bail',
      onConfirm: async () => {
        dismissAllDialogs()
        const res = await transferClientDocs({
          variables: {
            id: newClient._id,
            transferTo: meta._id,
            type: 'options',
            overwrite,
            sections: Object.keys(exportOptions).filter((key) => exportOptions[key])
          }
        })

        if (res) {
          enqueueSnackbar('The import was successful, page will be refreshed to update details', {
            variant: 'success'
          })
          window.location.reload()
        } else {
          enqueueSnackbar('Error importing from target client', { variant: 'error' })
        }
      },
      onCancel: () => dismissAllDialogs()
    })
  }

  function handleImport() {
    openDialog(SearchClientsDialog, {
      title: 'Choose a client to import from.',
      onConfirm: (newClient: Partial<ClientMetaDocument>) => {
        dismissDialog()
        if (newClient._id === meta._id) {
          enqueueSnackbar('Must choose a different Client', { variant: 'warning' })
          setNewClient(null)
        } else {
          openDialog(ConfirmationDialog, {
            message: 'Do you want to overwrite existing data, or keep it?',
            yesLabel: 'Overwrite',
            noLabel: 'Keep',
            onConfirm: () => {
              dismissDialog()
              handleConfirmImport({ newClient, overwrite: true })
            },
            onCancel: () => {
              dismissDialog()
              handleConfirmImport({ newClient, overwrite: false })
            }
          })
        }
      },
      onClose: () => {
        dismissDialog()
      }
    })
  }

  const handleExportCheckboxChange = (target: string) => {
    return function () {
      setExportOptions({ ...exportOptions, [target]: !exportOptions[target] })
    }
  }

  const checkForSelected = () => {
    for (const x in exportOptions) {
      if (exportOptions[x]) return false
    }
    return true
  }

  const queueDocUpdates = (json: any, selectedOptions?: ClientImportExportProps) => {
    const { options: newOptions, rates: newRates } = json

    if (newOptions) {
      const updatedOptions: OptionsDocument = {
        ...options,
        options: {
          ...options.options,
          ...newOptions,
          ...(selectedOptions?.quoteTypes && { intExtOptions: newOptions.options.intExtOptions }),
          ...(selectedOptions?.areaLabels && { areaTemplates: newOptions.options.areaTemplates }),
          ...(selectedOptions?.lineItems && { lineItems: newOptions.options.lineItems }),
          ...(selectedOptions?.products && { products: newOptions.options.products }),
          ...(selectedOptions?.rates && { rateSections: newOptions.options.rateSections }),
          ...(selectedOptions?.presentations && { presentationOptions: newOptions.options.presentationOptions }),
          ...(selectedOptions?.terms && { terms: newOptions.options.terms }),
          emails: {
            ...options.options?.emails,
            ...newOptions?.emails,
            values: {
              ...options.options?.emails?.values,
              ...newOptions?.emails?.values,
              ...(selectedOptions?.quoteEmails && {
                'send-invoice-client': newOptions?.emails?.values['send-invoice-client'],
                'send-quote-client': newOptions?.emails?.values['send-quote-client'],
                'send-work-order-staff': newOptions?.emails?.values['send-work-order-staff']
              }),
              ...(selectedOptions?.thankYouAcceptEmails && {
                'accept-thank-you': newOptions?.emails?.values['accept-thank-you']
              }),
              ...(selectedOptions?.thankYouDeclineEmails && {
                'decline-thank-you': newOptions?.emails?.values['decline-thank-you']
              }),
              ...(selectedOptions?.paymentReceivedEmail && {
                'payment-received': newOptions?.emails?.values['payment-received']
              })
            },
            ...(selectedOptions?.customEmailTemplates && {
              customTemplates: newOptions?.emails?.customTemplates ?? { order: [], values: {} }
            }),
            ...(selectedOptions?.followUps && {
              followUps: newOptions?.emails?.followUps ?? { order: [], values: {} }
            })
          }
        }
      }

      const cleanedOptions = cleanOptions({ options: updatedOptions })

      setFieldValue('options', cleanedOptions)
    }
    if (newRates) {
      const updatedRates: RatesDocument = {
        ...rates,
        rates: newRates
      }

      const cleanedRates = cleanRates({ rates: updatedRates })

      setFieldValue('rates', cleanedRates)
    }
  }

  const handleImportJson = () => {
    openDialog(ImportJsonDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  const handleImportAccount = () => {
    openDialog(ImportAccountDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  function handleClientSelect() {
    openDialog(SearchClientsDialog, {
      title: 'Choose a client to receive the transfer.',
      onConfirm: (newClient: Partial<ClientMetaDocument>) => {
        dismissDialog()
        if (newClient._id === meta._id) {
          enqueueSnackbar('Must choose a different Client', { variant: 'warning' })
          setNewClient(null)
        } else {
          setNewClient(newClient)
        }
      },
      onClose: () => {
        dismissDialog()
      }
    })
  }

  function handleMigrateImages(type: string) {
    openDialog(TaskConfirmationDialog, {
      message: `Are you sure you want to migrate ${type} images for this company? Confirmation email will be sent when complete.`,
      onConfirm: async (email: string) => {
        dismissDialog()
        await migrateCompanyImages({
          variables: {
            db: meta._id,
            type,
            email
          }
        })
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  async function handleResizeImages() {
    setResizeLoading(true)
    await resizeQuoteImages({
      variables: {
        db: meta._id,
        dateFrom: dates.dateFrom,
        dateTo: dates.dateTo
      }
    })
    setResizeLoading(false)
    enqueueSnackbar('Images are resizing, give it a minute or two and refresh quote w/ missing images to see updates.')
  }

  function handleDocTransfer(type: string) {
    openDialog(ConfirmationDialog, {
      title: `Confrim ${type} Transfer`,
      message: `Are you sure you want to transfer all owned ${type}'s from ${meta?.name}-(${meta._id}) to ${newClient?.name}-(${newClient._id})?`,
      scary: true,
      onConfirm: async () => {
        dismissDialog()
        const res = await transferClientDocs({
          variables: {
            id: meta._id,
            transferTo: newClient._id,
            type
          }
        })
        if (res) {
          enqueueSnackbar('Transfer has been started, you will receive an email summary when complete', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Error starting transfer', { variant: 'error' })
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDocDelete(type: string) {
    openDialog(ConfirmationDialog, {
      title: `Confirm ${type === 'both' ? 'Quote & Contact' : type} Delete`,
      message: `Are you sure you want to DELETE all company ${
        type === 'both' ? 'Quotes & Contacts' : `${type}'s`
      }? This can't be undone!`,
      scary: true,
      onConfirm: async () => {
        dismissDialog()
        setDeleteLoading(true)
        const res = await deleteClientDocs({
          variables: {
            id: meta._id,
            type
          }
        })
        if (res) {
          enqueueSnackbar('Delete processing has been started, you will receive an email summary when complete', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Error starting delete process', { variant: 'error' })
        }
        setDeleteLoading(false)
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const handleImportCSV = useCallback(async (type: string) => {
    openDialog(ImportCSVDialog, {
      title: type,
      onClose: () => {
        dismissDialog()
        setIsImporting(false)
      },
      onConfirm: async (file: File, owner?: CsvOwner, provider?: string) => {
        // Verify file before upload
        const reader = new FileReader()
        reader.onload = async (e) => {
          const text = e.target.result.toString()

          const { valid, errors } = checkImportedCSV(text, type, provider)

          if (valid) {
            try {
              await uploadFile({
                file,
                apolloClient,
                uploadOptions: {
                  companyId: meta._id,
                  docId: 'csv',
                  type,
                  provider,
                  owner,
                  s3UploadFeatureEnabled: true
                }
              })

              dismissDialog()
              // setIsDirty(true) // 'Times out' upload for 5 mins
              enqueueSnackbar('Your upload has begun! An email will be sent to you when complete.', {
                variant: 'success'
              })
            } catch (error) {
              console.error(error)
              enqueueSnackbar(`Error uploading CSV: ${error.message}`, { variant: 'error' })
            }
          } else {
            dismissDialog()
            enqueueSnackbar('Invalid CSV format, must use correct template.', { variant: 'error' })
            openDialog(AlertDialog, {
              title: 'CSV Format Errors',
              messages: errors,
              onConfirm: dismissDialog
            })
          }
        }

        reader.readAsText(file)
      },
      onConfirmJson: async (owner: { value: string; label: string; email: string }, jsonInput: string) => {
        try {
          let parsedQuote = JSON.parse(jsonInput)
          parsedQuote = {
            ...parsedQuote,
            owner: {
              ...owner
            }
          }
          await updateQuote({
            variables: {
              quote: parsedQuote,
              companyId: meta._id,
              clean: true
            }
          })
          enqueueSnackbar('Updated', { variant: 'success' })
          dismissDialog()
        } catch (error) {
          console.error(error)
          enqueueSnackbar(`Error saving json: ${error.message}`, { variant: 'error' })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isDirty) {
      const timeout = setTimeout(
        () => {
          setIsImporting(false)
          setIsDirty(false)
        },
        1000 * 60 * 5
      ) // 5 minutes

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isDirty])

  return (
    <>
      <FormSectionTitle title="Import" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={handleImportJson}
              variant={'contained'}
              color={'primary'}
              Icon={ArrowIcon}
              label={'Import JSON'}
            >
              <MenuItem onClick={handleImportAccount}>Import From Account</MenuItem>
            </SplitMenuButton>
          </Grid>
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={() => handleImportCSV('contacts')}
              variant={'contained'}
              color={'primary'}
              Icon={ArrowIcon}
              label={'Import Contacts'}
              loading={isImporting}
              disabled={isImporting || isDirty}
            >
              <MenuItem onClick={() => downloadCSVTemplate('contacts')}>Download Contacts Template</MenuItem>
            </SplitMenuButton>
          </Grid>
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={() => handleImportCSV('quotes')}
              variant={'contained'}
              color={'primary'}
              Icon={ArrowIcon}
              label={'Import Quotes'}
              loading={isImporting}
              disabled={isImporting || isDirty}
            >
              <MenuItem onClick={() => downloadCSVTemplate('quotes', 'default')}>
                Download Default Quotes Template
              </MenuItem>
              <MenuItem onClick={() => downloadCSVTemplate('quotes', 'er-quotes')}>
                Download ER Quotes Template
              </MenuItem>
            </SplitMenuButton>
          </Grid>
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={() => handleImportCSV('products')}
              variant={'contained'}
              color={'primary'}
              Icon={ArrowIcon}
              label={'Import Products'}
              loading={isImporting}
              disabled={isImporting || isDirty}
            >
              <MenuItem onClick={() => downloadCSVTemplate('products')}>Download Products Template</MenuItem>
            </SplitMenuButton>
          </Grid>
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={() => handleImportCSV('rates')}
              variant={'contained'}
              color={'primary'}
              Icon={ArrowIcon}
              label={'Import Rates'}
              loading={isImporting}
              disabled={isImporting || isDirty}
            >
              <MenuItem onClick={() => downloadCSVTemplate('rates', 'basic')}>Download Basic Rates Template</MenuItem>
              <MenuItem onClick={() => downloadCSVTemplate('rates', 'detailed')}>
                Download Detailed Rates Template
              </MenuItem>
            </SplitMenuButton>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Import/Export" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <FormControlLabel
              label={'Quote Types'}
              control={
                <Checkbox checked={exportOptions.quoteTypes} onChange={handleExportCheckboxChange('quoteTypes')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Area Labels'}
              control={
                <Checkbox checked={exportOptions.areaLabels} onChange={handleExportCheckboxChange('areaLabels')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Line Items'}
              control={
                <Checkbox checked={exportOptions.lineItems} onChange={handleExportCheckboxChange('lineItems')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Products'}
              control={<Checkbox checked={exportOptions.products} onChange={handleExportCheckboxChange('products')} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Rates'}
              control={<Checkbox checked={exportOptions.rates} onChange={handleExportCheckboxChange('rates')} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Presentations'}
              control={
                <Checkbox
                  checked={exportOptions.presentations}
                  onChange={handleExportCheckboxChange('presentations')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Terms'}
              control={<Checkbox checked={exportOptions.terms} onChange={handleExportCheckboxChange('terms')} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Disclaimers'}
              control={
                <Checkbox checked={exportOptions.disclaimers} onChange={handleExportCheckboxChange('disclaimers')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Quote Emails'}
              control={
                <Checkbox checked={exportOptions.quoteEmails} onChange={handleExportCheckboxChange('quoteEmails')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Custom Email Templates'}
              control={
                <Checkbox
                  checked={exportOptions.customEmailTemplates}
                  onChange={handleExportCheckboxChange('customEmailTemplates')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Thank You Accept Emails'}
              control={
                <Checkbox
                  checked={exportOptions.thankYouAcceptEmails}
                  onChange={handleExportCheckboxChange('thankYouAcceptEmails')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Thank You Decline Emails'}
              control={
                <Checkbox
                  checked={exportOptions.thankYouDeclineEmails}
                  onChange={handleExportCheckboxChange('thankYouDeclineEmails')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Payment Received Email'}
              control={
                <Checkbox
                  checked={exportOptions.paymentReceivedEmail}
                  onChange={handleExportCheckboxChange('paymentReceivedEmail')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Follow Ups'}
              control={
                <Checkbox checked={exportOptions.followUps} onChange={handleExportCheckboxChange('followUps')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleImport} disabled={checkForSelected()}>
              Import
            </Button>
            <Button style={{ marginLeft: '16px' }} onClick={handleExport} disabled={checkForSelected()}>
              Export
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => setExportOptions(defaultExportOptions)}
              disabled={checkForSelected()}
              variant="text"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Transfer Docs" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Button onClick={handleClientSelect}>{newClient ? 'Update' : 'Select'} Receiver</Button>
          </Grid>
          {newClient && (
            <>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Selected Receiver</Typography>
                <Typography variant={'body1'}>
                  {newClient?.name} - ({newClient._id})
                </Typography>
                <Typography variant={'body1'}>{newClient?.email}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => handleDocTransfer('templates')}>Transfer Templates</Button>
              </Grid>
            </>
          )}
        </Grid>
      </FormSection>
      <FormSectionTitle title="Delete Docs" />
      <FormSection>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Button disabled={deleteOption === ''} onClick={() => handleDocDelete(deleteOption)}>
              Delete
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Select
              name={'deleteOptionSelect'}
              disabled={deleteLoading}
              value={deleteOption}
              onChange={(ev: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                setDeleteOption(ev.target.value)
              }}
              displayEmpty
              variant={'outlined'}
              renderValue={(selectedValue: string) => {
                const selected = find(selectOptions, { value: selectedValue }) as OptionItem
                return selected?.label ?? 'Select Item...'
              }}
            >
              {(selectOptions ?? []).map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Resize Images" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
              Find quotes within given range that are missing s3 resizes on files, will add any missing sizes if found
            </Typography>
            <Button
              disabled={!dates.dateFrom || !dates.dateTo}
              onClick={handleResizeImages}
              loading={resizeLoading}
              style={{ marginBottom: '16px' }}
            >
              Resize Images
            </Button>
            <DateRangePicker
              startDate={dates.dateFrom ? moment(dates.dateFrom) : null}
              endDate={dates.dateTo ? moment(dates.dateTo) : null}
              onConfirm={(startDate, endDate) => {
                setDates({
                  dateFrom: startDate?.valueOf()?.toString() ?? '',
                  dateTo: endDate?.valueOf()?.toString() ?? ''
                })
              }}
              PopperProps={{
                placement: 'top-start'
              }}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Migrate Images" />
      <Typography variant="subtitle1" style={{ marginBottom: '16px' }}>
        Customer must have S3 Image Uploads feature enabled, then we can migrate their old images from cloudinary to s3.
      </Typography>
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
              Migrate all quote images from cloudinary to s3
            </Typography>
            <Button
              disabled={!getFeature({ options, path: 's3ImageUpload.enabled' })}
              onClick={() => handleMigrateImages('quote')}
              style={{ marginBottom: '16px' }}
            >
              Migrate Quote Images
            </Button>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: '16px' }}>
              Migrate all company images from cloudinary to s3
            </Typography>
            <Button
              disabled={!getFeature({ options, path: 's3ImageUpload.enabled' })}
              onClick={() => handleMigrateImages('company')}
              style={{ marginBottom: '16px' }}
            >
              Migrate Options Images
            </Button>
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientImportExport
