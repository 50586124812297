export type Filter = ValueFilter | OperatorFilter
export type ValueFilter =
  | RangeFilter
  | FuzzyFilter
  | FuzzyArrayFilter
  | EqualFilter
  | GreaterThanFilter
  | LessThanFilter
  | InFilter
  | WildCardFilter
  | ExistsFilter
export type OperatorFilter = OrFilter | AndFilter

export interface RangeFilter {
  id: string
  type: 'range'
  value: [any, any] | [any]
  not?: boolean
}

export interface FuzzyFilter {
  id: string
  type: 'fuzzy'
  value: string
  not?: boolean
}

export interface FuzzyArrayFilter {
  id: string
  type: 'fuzzyArray'
  value: Array<string | number | boolean>
  not?: boolean
}

export interface EqualFilter {
  id: string
  type: 'equal'
  value: boolean | string | number | Array<string | number | boolean>
  not?: boolean
}

export interface GreaterThanFilter {
  id: string
  type: 'greaterThan'
  not?: boolean
  value: number
}

export interface LessThanFilter {
  id: string
  type: 'lessThan'
  not?: boolean
  value: number
}

export interface EqualFilter {
  id: string
  type: 'equal'
  value: boolean | string | number | Array<string | number | boolean>
  not?: boolean
}

export interface ExistsFilter {
  id: string
  type: 'exists'
  value?: string
  not?: boolean
}

export interface WildCardFilter {
  id: string
  type: 'wildcard'
  before?: boolean
  after?: boolean
  value: boolean | string | number | Array<string | number | boolean>
  not?: boolean
}

export interface OrFilter {
  type: 'or'
  value: Filter[]
  not?: boolean
}

export interface AndFilter {
  type: 'and'
  value: Filter[]
  not?: boolean
}

/**
 * Matches an array containing any of the values
 */
export interface InFilter {
  type: 'in'
  id: string
  value: Array<string | number | boolean>
  not?: boolean
}

/**
 * Builds a lucene query using the filters and normal text from query
 *
 * @param args.query - adds a wildcard search to the query (ideally it's the input from a search bar)
 * @param args.filters - adds filters to the lucene query
 */
export function jsonToLucene({ query = '', filters = [] }: { query?: string; filters?: Filter[] }) {
  const trimmedQuery = query
    .toString()
    .trim()
    .replace(/[\*\t]/gi, '')
  const splitQuery = trimmedQuery.split(' ')

  // if query has spaces join them into AND operations
  let baseQuery = trimmedQuery
    ? splitQuery.reduce((result, term, index) => {
        if (term === '') {
          return result
        }

        if (result && index !== 0) {
          result += ' OR '
        }

        return result + `default:${escapeValue(term)}*`
      }, '')
    : ''

  // Add in full string search on baseQuery if multi-word query
  if (splitQuery.length > 1) {
    const testTerm = splitQuery.reduce((result, term, index) => {
      if (term === '') {
        return result
      }

      if (result && index === splitQuery.length - 1) {
        return `default: ${result + `${escapeValue(term)}`}*`
      }
      return result + `${term === '' ? term : escapeValue(term)} `
    }, '')
    baseQuery = [baseQuery, testTerm].filter(Boolean).join(' OR ')
  }

  const luceneFilters = convertFiltersToLucene({
    filters
  })

  // join baseQuery and filters together
  if (luceneFilters.trim()) {
    return `${baseQuery ? baseQuery + ' AND' : ''} ${luceneFilters}`.trim()
  } else {
    return baseQuery
  }
}

function convertFiltersToLucene({ filters, operator = 'AND' }: { filters: Filter[]; operator?: string }): string {
  if (filters) {
    return filters.reduce((query, filter) => {
      // the query so far + operator to chain the next filter
      const prefix = `${query ? `${query} ${operator} ` : ''}`
      if (isOrFilter(filter)) {
        if (filter.value && filter.value.length > 0) {
          return `${prefix}(${convertFiltersToLucene({
            filters: filter.value,
            operator: 'OR'
          })})`
        }
      }

      if (isAndFilter(filter)) {
        if (filter.value && filter.value.length > 0) {
          return `${prefix}(${convertFiltersToLucene({
            filters: filter.value,
            operator: 'AND'
          })})`
        }
      }

      if (isExistsFilter(filter)) {
        return `${prefix}${addNot(filter)}(${filter.id}:["" TO *])`
      }

      if (isGreaterThanFilter(filter)) {
        return `${prefix}${addNot(filter)}(${filter.id}:>${filter.value})`
      }

      if (isLessThanFilter(filter)) {
        return `${prefix}${addNot(filter)}(${filter.id}:<${filter.value})`
      }

      const value = escapeValue(filter.value)

      if (filterHasValue(filter)) {
        if (isRangeFilter(filter)) {
          if (!Array.isArray(value)) {
            throw Error('Value must be array for "range" filter')
          }

          // id:["value[0]" TO "value[1]"]
          return `${prefix}${addNot(filter)}(${filter.id}:["${isValidValue(value[0]) ? value[0] : '-Infinity'}" TO "${
            isValidValue(value[1]) ? value[1] : 'Infinity'
          }"])`
        }

        if (isFuzzyFilter(filter)) {
          // id:value~
          return `${prefix}${addNot(filter)}(${filter.id}:(${value}~0.6))`
        }

        if (isFuzzyArrayFilter(filter)) {
          // id:("value1", "value2")
          const asArray = value as Array<string | number | boolean>
          return `${prefix}${addNot(filter)}(${filter.id}:(${asArray.map((v) => `"${v}"`).join(' ')}))`
        }

        if (isEqualFilter(filter)) {
          if (Array.isArray(value)) {
            const asArray = value as any[]

            // id:(+"value1" +"value2")
            return `${prefix}${addNot(filter)}(${filter.id}:(${asArray.map((v) => `+"${v}"`).join(' ')}))`
          } else {
            // add quotes around string value
            const formattedValue = typeof value === 'string' ? `"${value}"` : value

            // id:value
            return `${prefix}${addNot(filter)}(${filter.id}:${formattedValue})`
          }
        }

        if (isWildCardFilter(filter)) {
          // id:value
          return `${prefix}${addNot(filter)}(${filter.id}:${filter.before ? '*' : ''}${value}${
            filter.after ? '*' : ''
          })`
        }

        if (isInFilter(filter)) {
          const asArray = Array.isArray(value) ? value : ([value] as any[])

          if (!asArray.length) {
            return ''
          }

          // return `${prefix}${addNot(filter)}(${filter.id}:(${asArray.map((v) => `"${v}"`).join(' ')}))`
          return `${prefix}${addNot(filter)}(${convertFiltersToLucene({
            filters: asArray.map((v) => ({
              type: 'equal',
              id: filter.id,
              value: v
            })),
            operator: 'OR'
          })})`
        }
      }

      return query
    }, '')
  } else {
    return ''
  }
}

function filterHasValue(filter: Filter) {
  if (isOrFilter(filter)) {
    return false
  }

  if (isRangeFilter(filter)) {
    return isValidValue(filter.value[0]) || isValidValue(filter.value[1])
  }

  return isValidValue(filter.value)
}

function isValidValue(value: any) {
  return typeof value !== 'undefined' && value !== ''
}

function isRangeFilter(filter: Filter): filter is RangeFilter {
  return filter.type === 'range'
}

function isFuzzyFilter(filter: Filter): filter is FuzzyFilter {
  return filter.type === 'fuzzy'
}

function isFuzzyArrayFilter(filter: Filter): filter is FuzzyArrayFilter {
  return filter.type === 'fuzzyArray'
}

function isExistsFilter(filter: Filter): filter is ExistsFilter {
  return filter.type === 'exists'
}

function isEqualFilter(filter: Filter): filter is EqualFilter {
  return filter.type === 'equal'
}

function isGreaterThanFilter(filter: Filter): filter is GreaterThanFilter {
  return filter.type === 'greaterThan'
}

function isLessThanFilter(filter: Filter): filter is LessThanFilter {
  return filter.type === 'lessThan'
}

function isWildCardFilter(filter: Filter): filter is WildCardFilter {
  return filter.type === 'wildcard'
}

function isOrFilter(filter: OperatorFilter | Filter): filter is OrFilter {
  return filter.type === 'or'
}

function isAndFilter(filter: OperatorFilter | Filter): filter is AndFilter {
  return filter.type === 'and'
}

function isInFilter(filter: OperatorFilter | Filter): filter is InFilter {
  return filter.type === 'in'
}

function addNot(filter: Filter) {
  if (filter.not) {
    return 'NOT '
  }

  return ''
}

function escapeValue<T>(value: T): T {
  if (typeof value === 'string') {
    const isAlreadyEscaped = value.match(/\\/g)?.length
    if (isAlreadyEscaped) {
      return value
    }
    // escape the following special characters: + - && || ! ( ) { } [ ] ^ " ~ * ? :
    // (i don't know how to turn this into one regex exp - if you do, please feel free!)

    // @ts-ignore - also this ts error is whack, and only started happening since ts 3.9
    return value
      .replace(/\+/g, '\\$&')
      .replace(/-/g, '\\$&')
      .replace(/&&/g, '\\$&')
      .replace(/\|\|/g, '\\$&')
      .replace(/!/g, '\\$&')
      .replace(/\(/g, '\\$&')
      .replace(/\)/g, '\\$&')
      .replace(/\{/g, '\\$&')
      .replace(/\}/g, '\\$&')
      .replace(/\[/g, '\\$&')
      .replace(/\]/g, '\\$&')
      .replace(/\^/g, '\\$&')
      .replace(/"/g, '\\$&')
      .replace(/~/g, '\\$&')
      .replace(/\*/g, '\\$&')
      .replace(/\?/g, '\\$&')
      .replace(/\//g, '\\$&')
      .replace(/:/g, '\\$&')
  } else if (Array.isArray(value)) {
    // @ts-ignore
    return value.map(escapeValue)
  }

  return value
}
