import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { Checkbox, Collapse, FormControlLabel, Grid, Switch, Typography } from '@ui/paintscout'
import type { PartnerContentFeatures, PartnerPresentationOptions, PresentationOption } from 'paintscout'
import type { PartnerStrings } from '../PresentationPartnerDialog'

const useStyles = makeStyles<Theme, BenjaminMooreDialogSectionProps>(
  (_theme) => ({
    root: {},
    pageLabel: {
      paddingTop: '12px'
    },
    pagesContainer: {
      padding: '20px'
    },
    sectionsContainer: {
      padding: '0 10px'
    }
  }),
  { name: 'BenjaminMooreDialogSection' }
)

export interface BenjaminMooreDialogSectionProps {
  classes?: StyleClasses<typeof useStyles>
  partnerContentFeatures: PartnerContentFeatures
  handlePartnerEnableOnChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    partner: PartnerStrings
  ) => void
  partnerContentEnabled: PartnerPresentationOptions
  presentation: PresentationOption
}

function BenjaminMooreDialogSection(props: BenjaminMooreDialogSectionProps) {
  const { partnerContentFeatures, handlePartnerEnableOnChange, partnerContentEnabled } = props

  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      {partnerContentFeatures?.benjaminMoore?.presentationPages?.enabled && (
        <>
          <FormControlLabel
            label={'Benjamin Moore'}
            name={'enabled'}
            control={
              <Switch
                onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'benjaminMoore')}
                checked={partnerContentEnabled?.benjaminMoore?.enabled}
              />
            }
          />

          <Collapse show={partnerContentEnabled?.benjaminMoore?.enabled}>
            <Grid container direction="column" className={classes.pagesContainer}>
              <Grid item>
                <Typography variant="h4">Pages</Typography>
              </Grid>

              <FormControlLabel
                label={'Why Benjamin Moore'}
                name={'pages.whyBenjaminMoore.enabled'}
                className={classes.pageLabel}
                control={
                  <Switch
                    onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'benjaminMoore')}
                    checked={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.enabled}
                  />
                }
              />

              <Collapse show={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.enabled}>
                <Grid item container direction="column">
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Legacy of Craftsmanship'}
                      control={
                        <Checkbox
                          name={'pages.whyBenjaminMoore.legacyofCraftsmanship'}
                          checked={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.legacyofCraftsmanship}
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'benjaminMoore')}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>

              <Collapse show={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.enabled}>
                <Grid item container direction="column">
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Unmatchable Color'}
                      control={
                        <Checkbox
                          name={'pages.whyBenjaminMoore.unmatchableColor'}
                          checked={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.unmatchableColor}
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'benjaminMoore')}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>

              <Collapse show={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.enabled}>
                <Grid item container direction="column">
                  <Grid item className={classes.sectionsContainer}>
                    <FormControlLabel
                      label={'Local Support'}
                      control={
                        <Checkbox
                          name={'pages.whyBenjaminMoore.localSupport'}
                          checked={partnerContentEnabled?.benjaminMoore?.pages?.whyBenjaminMoore?.localSupport}
                          onChange={(event, checked) => handlePartnerEnableOnChange(event, checked, 'benjaminMoore')}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Collapse>
        </>
      )}
    </div>
  )
}

export default BenjaminMooreDialogSection
